import './header.scss';

import React, { useEffect, useState, createContext, SetStateAction, Dispatch } from 'react';
import { Storage, Translate } from 'react-jhipster';
import LoadingBar from 'react-redux-loading-bar';
import { Button, Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { AccountMenu } from '../menus';
import { Brand, MyTasks } from './header-components';

import { getEntities as getMyTasks } from 'app/entities/tasks/tasks.reducer';
import { HrMenuData } from '../menus/hr-data';
import { UserAccessData } from '../menus/user-access';
import { AccessProfilesData } from '../menus/access-profiles';
import { OrdersRoutesData } from '../menus/orders-routes';
import { ConfigurationData } from '../menus/configuration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

interface IHeaderState {
  disableNavClick: 'close' | 'open';
  setDisableNavClick: Dispatch<SetStateAction<string>>;
}

export const ContextHeaderState = createContext<IHeaderState>({
  disableNavClick: null,
  setDisableNavClick: () => null,
});

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [disableNavClick, setDisableNavClick] = useState<'close' | 'open'>('close');
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getMyTasks({}));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 768) {
        setDisableNavClick('open');
      }
    }

    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.local.set('locale', langKey);
    dispatch(setLocale(langKey));
    setDisableNavClick('close');
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggledisablenavclick = () => setDisableNavClick(disableNavClick === 'open' ? 'close' : 'open');

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header" className={disableNavClick === 'open' ? 'app-header-full' : 'app-header'}>
      <ContextHeaderState.Provider value={{ disableNavClick, setDisableNavClick }}>
        {renderDevRibbon()}
        <LoadingBar className="loading-bar" />
        <Navbar data-cy="navbar" dark expand="md" fixed="top" className="bg-secondary navbar-container">
          <Button onClick={toggledisablenavclick} className="disable-nav-click my-1 hamburger-menu-navbar">
            <FontAwesomeIcon icon={faBars} />{' '}
          </Button>
          <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
          <Brand />
          <Collapse isOpen={menuOpen} navbar className="navbar-full">
            <Nav id="header-tabs" className={props.isAuthenticated ? 'ms-auto d-flex flex-wrap' : 'ms-1 d-flex flex-wrap'} navbar>
              {props.isAuthenticated && <MyTasks />}
              {props.isAuthenticated && <HrMenuData />}
              {props.isAuthenticated && <UserAccessData />}
              {props.isAuthenticated && <AccessProfilesData />}
              {props.isAuthenticated && <OrdersRoutesData />}
              {props.isAuthenticated && <ConfigurationData isAdmin={props.isAdmin} />}
              <AccountMenu
                isAuthenticated={props.isAuthenticated}
                isAdmin={props.isAdmin}
                isOpenAPIEnabled={props.isOpenAPIEnabled}
                currentLocale={props.currentLocale}
                handleLocaleChange={handleLocaleChange}
              />
            </Nav>
          </Collapse>
        </Navbar>
      </ContextHeaderState.Provider>
    </div>
  );
};

export default Header;
