import React from 'react';
import { translate } from 'react-jhipster';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { ICompactNode, NodeType } from './types';
import { createTree, getLastNodeBlockWithChildren } from './utils';

interface CompactExpressionBuilderProps {
  tree: ICompactNode | string;
  truncate?: boolean;
  expressionId?: string;
}

interface CompactNodeProps {
  node: ICompactNode;
  depth: number;
  truncate?: boolean;
}

function CompactNode({ node, depth, truncate }: CompactNodeProps) {
  if (!node) return null;

  const nodeProps = {
    key: node.id,
  };

  if (node?.type == NodeType._IF || node?.type == NodeType._THEN || node?.type == NodeType._ELSE || node?.type == NodeType._SWITCH) {
    return (
      <span {...nodeProps}>
        <Badge color="danger">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>
        {node.nodes.map((n, index) => (
          <CompactNode key={`${n.name}-${index}`} node={n} depth={depth + 1} truncate={truncate} />
        ))}
      </span>
    );
  } else if (node?.type == NodeType._CONDITIONAL) {
    return (
      <span {...nodeProps}>
        {node.nodes.map((n, index) => (
          <CompactNode key={`${n.name}-${index}`} node={n} depth={depth + 1} truncate={truncate} />
        ))}
      </span>
    );
  } else if (node?.type == NodeType._OPERATORS) {
    return (
      <span {...nodeProps}>
        {' ( '}
        {node.nodes.map((n, index) => {
          return (
            <React.Fragment key={`${n.name}-${index}`}>
              <CompactNode node={n} depth={depth + 1} truncate={truncate} />
              {index < node.nodes.length - 1 ? (
                <Badge color="primary">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>
              ) : null}
            </React.Fragment>
          );
        })}
        {' ) '}
      </span>
    );
  } else if (node?.type == NodeType._FUNCTIONS) {
    return (
      <span {...nodeProps}>
        <Badge color="warning">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>
        {' ( '}
        {node.nodes.map((n, index) => {
          return (
            <React.Fragment key={`${n.name}-${index}`}>
              <CompactNode node={n} depth={depth + 1} truncate={truncate} />
              {index < node.nodes.length - 1 ? <span>,</span> : null}
            </React.Fragment>
          );
        })}
        {' ) '}
      </span>
    );
  } else if (node?.type == NodeType._AGGREGATORS) {
    return (
      <span {...nodeProps}>
        {node.nodes.map((n, index) => {
          return (
            <React.Fragment key={`${n.name}-${index}`}>
              <CompactNode node={n} depth={depth + 1} truncate={truncate} />
              {index < node.nodes.length - 1 ? (
                <span>
                  {' '}
                  <Badge color="primary">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>{' '}
                </span>
              ) : null}
            </React.Fragment>
          );
        })}
      </span>
    );
  } else if (node?.type == NodeType._CONDITIONS) {
    return (
      <span {...nodeProps}>
        <React.Fragment>
          {node.nodes.length > 0} <CompactNode node={node.nodes[0]} depth={depth + 1} truncate={truncate} />
          <Badge color="warning">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>
          {node.nodes.length > 1} <CompactNode node={node.nodes[1]} depth={depth + 1} truncate={truncate} />
        </React.Fragment>
      </span>
    );
  } else {
    return (
      <React.Fragment {...nodeProps}>
        <Badge color="secondary">{node?.value}</Badge>
        {node?.type == '_INTERMEDIARY'
          ? node?.nodes.map((n, index) => {
              return (
                <React.Fragment key={`${n.name}-${index}`}>
                  <CompactNode node={n} depth={depth + 1} truncate={truncate} />
                </React.Fragment>
              );
            })
          : null}
      </React.Fragment>
    );
  }
}

function CompactExpressionBuilder({ tree, truncate = true, expressionId }: CompactExpressionBuilderProps) {
  if (!tree || tree == 'null') return null;

  let parsedTree = { ...(tree && typeof tree == 'string' ? createTree(JSON.parse(tree)) : tree) };

  if (parsedTree && truncate) {
    let lastNodeWithChildren = getLastNodeBlockWithChildren(parsedTree);
    const intermediaryNode: ICompactNode = {
      id: uuidv4(),
      name: 'INTERMEDIARY',
      value: '',
      nodes: [lastNodeWithChildren],
      type: '_INTERMEDIARY',
    };
    if (lastNodeWithChildren == parsedTree) {
      parsedTree = intermediaryNode;
    } else {
      parsedTree.nodes = [intermediaryNode];
    }
  }

  return (
    <div className="expression-builder-container" id={`expression-builder-${expressionId ? expressionId : parsedTree.id}`}>
      <CompactNode node={parsedTree} depth={0} truncate={truncate} />
      {truncate ? (
        <UncontrolledTooltip placement="right" target={`expression-builder-${expressionId ? expressionId : parsedTree.id}`}>
          <CompactExpressionBuilder tree={tree} truncate={false} />
        </UncontrolledTooltip>
      ) : null}
    </div>
  );
}

export default CompactExpressionBuilder;
