import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, InputGroup, FormGroup, Form, Row, Col, Table, Card, UncontrolledTooltip } from 'reactstrap';
import { Translate, translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import {
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
  findCustomAttributeValueByKey,
} from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IInSpecificPermission } from 'app/shared/model/in-specific-permission.model';
import { exportEntities, searchEntities, getEntities } from './in-specific-permission.reducer';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';

export const InSpecificPermission = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  const inSpecificPermissionList = useAppSelector(state => state.inSpecificPermission.entities);
  const loading = useAppSelector(state => state.inSpecificPermission.loading);
  const totalItems = useAppSelector(state => state.inSpecificPermission.totalItems);
  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(def => def.visibleInList)
  );
  const locale = useAppSelector(state => state.locale.currentLocale);
  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  return (
    <Card className="jh-card">
      <h2 id="in-specific-permission-heading" data-cy="InSpecificPermissionHeading">
        <Translate contentKey="iamdentityApp.inSpecificPermission.home.title">In Specific Permissions</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="iamdentityApp.inSpecificPermission.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} /> <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.id">ID</Translate> {handleSortIconsTable('id')}
              </th>
              <th className="hand" onClick={sort('applicationCode')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.applicationCode">Application Code</Translate>{' '}
                {handleSortIconsTable('applicationCode')}
              </th>
              <th className="hand" onClick={sort('applicationResourceCode')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.applicationResourceCode">Application Resource Code</Translate>{' '}
                {handleSortIconsTable('applicationResourceCode')}
              </th>
              <th className="hand" onClick={sort('userBusinessKey')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.userBusinessKey">User Business Key</Translate>{' '}
                {handleSortIconsTable('userBusinessKey')}
              </th>
              <th className="hand" onClick={sort('permissionCode')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.permissionCode">Permission Code</Translate>{' '}
                {handleSortIconsTable('permissionCode')}
              </th>
              <th className="hand" onClick={sort('status')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.status">Status</Translate> {handleSortIconsTable('status')}
              </th>
              <th className="hand" onClick={sort('startDate')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.startDate">Start Date</Translate>{' '}
                {handleSortIconsTable('startDate')}
              </th>
              <th className="hand" onClick={sort('endDate')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.endDate">End Date</Translate> {handleSortIconsTable('endDate')}
              </th>
              <th className="hand" onClick={sort('importSession')}>
                <Translate contentKey="iamdentityApp.inSpecificPermission.importSession">Import Session</Translate>{' '}
                {handleSortIconsTable('importSession')}
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th className="hand" key={cad.id}>
                  {cad.displayName}
                </th>
              ))}
              <th className="text-right">
                <span>
                  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>
                    <FontAwesomeIcon icon="magnifying-glass" />
                  </Button>
                </span>
                <UncontrolledTooltip placement="top" target="toggle-filter">
                  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>
                </UncontrolledTooltip>
              </th>
            </tr>
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'ignore',
                  },
                  {
                    name: 'applicationCode',
                    type: 'string',
                  },
                  {
                    name: 'applicationResourceCode',
                    type: 'string',
                  },
                  {
                    name: 'userBusinessKey',
                    type: 'string',
                  },
                  {
                    name: 'permissionCode',
                    type: 'string',
                  },
                  {
                    name: 'status',
                    type: 'string',
                  },
                  {
                    name: 'startDate',
                    type: 'date',
                  },
                  {
                    name: 'endDate',
                    type: 'date',
                  },
                  {
                    name: 'importSession',
                    type: 'string',
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
          </thead>
          <tbody>
            {inSpecificPermissionList.map((inSpecificPermission, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/in-specific-permission/${inSpecificPermission.id}`} color="link" size="sm">
                    {inSpecificPermission.id}
                  </Button>
                </td>
                <td>{inSpecificPermission.applicationCode}</td>
                <td>{inSpecificPermission.applicationResourceCode}</td>
                <td>{inSpecificPermission.userBusinessKey}</td>
                <td>{inSpecificPermission.permissionCode}</td>
                <td>{inSpecificPermission.status}</td>
                <td>
                  {inSpecificPermission.startDate ? (
                    <TextFormat type="date" value={inSpecificPermission.startDate} format={APP_LOCAL_DATE_FORMAT} />
                  ) : null}
                </td>
                <td>
                  {inSpecificPermission.endDate ? (
                    <TextFormat type="date" value={inSpecificPermission.endDate} format={APP_LOCAL_DATE_FORMAT} />
                  ) : null}
                </td>
                <td>
                  {inSpecificPermission.importSession ? (
                    <Link to={`/import-session/${inSpecificPermission.importSession.id}`}>{inSpecificPermission.importSession.id}</Link>
                  ) : (
                    ''
                  )}
                </td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>{findCustomAttributeValueByKey(cad, inSpecificPermission, locale)}</td>
                ))}
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={`/in-specific-permission/${inSpecificPermission.id}`}
                      color="info"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!inSpecificPermissionList || inSpecificPermissionList.length < 1) && !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="iamdentityApp.inSpecificPermission.home.notFound">No In Specific Permissions found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={8 && inSpecificPermissionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default InSpecificPermission;
