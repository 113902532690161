import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

import { apiUrl as applicationsUrl } from 'app/entities/application/application.reducer';
import { apiUrl as employeesUrl } from 'app/entities/employee/employee.reducer';
import { apiUrl as orderedUserIdentityUrl } from 'app/entities/ordered-user-identity/ordered-user-identity.reducer';
import { apiUrl as ordersUrl } from 'app/entities/orders/orders.reducer';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { OperationType } from 'app/shared/model/enumerations/operation-type.model';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, resetEntity, updateEntity } from './ordered-user-identity.reducer';
import { apiUrl as userIdentityUrl } from 'app/entities/user-identity/user-identity.reducer';
import Employee from '../employee/employee';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';

interface ISearchParamsObject {
  order?: { id: number };
  operationType?: OperationType;
  grantedBy?: PermissionGrantLevel;
}

export const OrderedUserIdentityUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const userIdentities = useAppSelector(state => state.userIdentity.entities);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));

  const { orderedUserIdentityId: id } = useParams<'orderedUserIdentityId'>();
  const isNew = id === undefined;

  const orderedUserIdentityEntity: IOrderedUserIdentity = useAppSelector(state => state.orderedUserIdentity.entity);
  const loading = useAppSelector(state => state.orderedUserIdentity.loading);
  const updating = useAppSelector(state => state.orderedUserIdentity.updating);
  const updateSuccess = useAppSelector(state => state.orderedUserIdentity.updateSuccess);
  const permissionGrantLevelValues = Object.values(PermissionGrantLevel);
  const orderExecutionStatusValues = Object.values(OrderExecutionStatus);
  const operationTypeValues = Object.values(OperationType);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_USER_IDENTITY));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [orderedUserIdentityEntity]);

  const saveEntity = values => {
    values.scheduleTime = convertDateTimeToServer(
      moment(values.order.scheduleTime, convertDateFormat(locale, 'instant')).format('MM/DD/YYYY HH:mm')
    );
    values.executionStartTime = convertDateTimeToServer(values.executionStartTime);
    values.executionEndTime = convertDateTimeToServer(values.executionEndTime);
    values.businessValidityStart = convertDateTimeToServer(
      moment(values.order.scheduleTime, convertDateFormat(locale, 'instant')).format('MM/DD/YYYY HH:mm')
    );
    values.businessValidityEnd = convertDateTimeToServer(
      moment(values.order.orderValidUntil, convertDateFormat(locale, 'instant')).format('MM/DD/YYYY HH:mm')
    );
    values.returnedBusinessValidityStart = convertDateTimeToServer(values.returnedBusinessValidityStart);
    values.returnedBusinessValidityEnd = convertDateTimeToServer(values.returnedBusinessValidityEnd);

    const entity = {
      ...orderedUserIdentityEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        orderedUserIdentityEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          // scheduleTime: displayDefaultDateTime(),
          executionStartTime: displayDefaultDateTime(),
          executionEndTime: displayDefaultDateTime(),
          // businessValidityStart: displayDefaultDateTime(),
          // businessValidityEnd: displayDefaultDateTime(),
          returnedBusinessValidityStart: displayDefaultDateTime(),
          returnedBusinessValidityEnd: displayDefaultDateTime(),
          ...searchParamsObject,
        }
      : {
          ...orderedUserIdentityEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, orderedUserIdentityEntity),
          grantedBy: orderedUserIdentityEntity.grantedBy,
          executionStatus: orderedUserIdentityEntity.executionStatus,
          scheduleTime: convertDateTimeFromServer(orderedUserIdentityEntity.scheduleTime),
          executionStartTime: convertDateTimeFromServer(orderedUserIdentityEntity.executionStartTime),
          executionEndTime: convertDateTimeFromServer(orderedUserIdentityEntity.executionEndTime),
          businessValidityStart: convertDateTimeFromServer(orderedUserIdentityEntity.businessValidityStart),
          businessValidityEnd: convertDateTimeFromServer(orderedUserIdentityEntity.businessValidityEnd),
          returnedBusinessValidityStart: convertDateTimeFromServer(orderedUserIdentityEntity.returnedBusinessValidityStart),
          returnedBusinessValidityEnd: convertDateTimeFromServer(orderedUserIdentityEntity.returnedBusinessValidityEnd),
          order: orderedUserIdentityEntity?.order,
          application: orderedUserIdentityEntity?.application,
          employee: orderedUserIdentityEntity?.employee,
          dependsOn: orderedUserIdentityEntity?.dependsOn,
          operationType: orderedUserIdentityEntity.operationType,
          ...searchParamsObject,
        };

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchBusinessValidityStart = watch('businessValidityStart');
  const watchBusinessValidityEnd = watch('businessValidityEnd');

  return (
    <Card className="jh-card pad-1">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.orderedUserIdentity.home.createOrEditLabel" data-cy="OrderedUserIdentityCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.orderedUserIdentity.home.createOrEditLabel">
              Create or edit a OrderedUserIdentity
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="ordered-user-identity-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.userName')}
                id="ordered-user-identity-userName"
                name="userName"
                data-cy="userName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.alternativeUserName')}
                id="ordered-user-identity-alternativeUserName"
                name="alternativeUserName"
                data-cy="alternativeUserName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.associatedEmailAddress')}
                id="ordered-user-identity-associatedEmailAddress"
                name="associatedEmailAddress"
                data-cy="associatedEmailAddress"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.displayName')}
                id="ordered-user-identity-displayName"
                name="displayName"
                data-cy="displayName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.initialPassword')}
                id="ordered-user-identity-initialPassword"
                name="initialPassword"
                data-cy="initialPassword"
                type="password"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.mustChangePasswordOnFirstLogin')}
                id="ordered-user-identity-mustChangePasswordOnFirstLogin"
                name="mustChangePasswordOnFirstLogin"
                data-cy="mustChangePasswordOnFirstLogin"
                check
                type="checkbox"
              />
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="dateNextChangePassword"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="ordered-user-identity-dateNextChangePassword" className="form-label">
                        {translate('iamdentityApp.orderedUserIdentity.dateNextChangePassword')}
                      </label>
                      <DatePicker
                        maxDate={watchBusinessValidityEnd ? moment(watchBusinessValidityEnd, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="ordered-user-identity-dateNextChangePassword"
                        data-cy="dateNextChangePassword"
                        disabled
                      />
                    </div>
                  )}
                />
              </div>
              {/* <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.returnedUserName')}
                id="ordered-user-identity-returnedUserName"
                name="returnedUserName"
                data-cy="returnedUserName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.returnedAlternativeUserName')}
                id="ordered-user-identity-returnedAlternativeUserName"
                name="returnedAlternativeUserName"
                data-cy="returnedAlternativeUserName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.returnedAssociatedEmailAddress')}
                id="ordered-user-identity-returnedAssociatedEmailAddress"
                name="returnedAssociatedEmailAddress"
                data-cy="returnedAssociatedEmailAddress"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.returnedDisplayName')}
                id="ordered-user-identity-returnedDisplayName"
                name="returnedDisplayName"
                data-cy="returnedDisplayName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.returnedInitialPassword')}
                id="ordered-user-identity-returnedInitialPassword"
                name="returnedInitialPassword"
                data-cy="returnedInitialPassword"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.returnedMustChangePasswordOnFirstLogin')}
                id="ordered-user-identity-returnedMustChangePasswordOnFirstLogin"
                name="returnedMustChangePasswordOnFirstLogin"
                data-cy="returnedMustChangePasswordOnFirstLogin"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.returnedDateNextChangePassword')}
                id="ordered-user-identity-returnedDateNextChangePassword"
                name="returnedDateNextChangePassword"
                data-cy="returnedDateNextChangePassword"
                type="date"
              /> */}
              <ValidatedField
                register={register}
                disabled={!!searchParamsObject?.grantedBy || !isNew}
                readOnly
                label={translate('iamdentityApp.orderedUserIdentity.grantedBy')}
                id="ordered-user-identity-grantedBy"
                name="grantedBy"
                data-cy="grantedBy"
                type="select"
              >
                {permissionGrantLevelValues.map(permissionGrantLevel => (
                  <option value={permissionGrantLevel} key={permissionGrantLevel}>
                    {translate('iamdentityApp.PermissionGrantLevel.' + permissionGrantLevel)}
                  </option>
                ))}
              </ValidatedField>
              {/* <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.executionStatus')}
                id="ordered-user-identity-executionStatus"
                name="executionStatus"
                data-cy="executionStatus"
                type="select"
              >
                {orderExecutionStatusValues.map(orderExecutionStatus => (
                  <option value={orderExecutionStatus} key={orderExecutionStatus}>
                    {translate('iamdentityApp.OrderExecutionStatus.' + orderExecutionStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.executionResult')}
                id="ordered-user-identity-executionResult"
                name="executionResult"
                data-cy="executionResult"
                type="text"
              /> */}
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="scheduleTime"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="ordered-user-identity-scheduleTime" className="form-label">
                        {translate('iamdentityApp.orderedUserIdentity.scheduleTime')}
                      </label>
                      <DatePicker
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'instant')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="ordered-user-identity-scheduleTime"
                        data-cy="scheduleTime"
                        disabled
                      />
                    </div>
                  )}
                />
              </div>
              {/* <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.executionStartTime')}
                id="ordered-user-identity-executionStartTime"
                name="executionStartTime"
                data-cy="executionStartTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              /> */}
              {/* <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.executionEndTime')}
                id="ordered-user-identity-executionEndTime"
                name="executionEndTime"
                data-cy="executionEndTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.executionEstimatedDuration')}
                id="ordered-user-identity-executionEstimatedDuration"
                name="executionEstimatedDuration"
                data-cy="executionEstimatedDuration"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.executionActualDuration')}
                id="ordered-user-identity-executionActualDuration"
                name="executionActualDuration"
                data-cy="executionActualDuration"
                type="text"
              /> */}
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="businessValidityStart"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="ordered-user-identity-businessValidityStart" className="form-label">
                        {translate('iamdentityApp.orderedUserIdentity.businessValidityStart')}
                      </label>
                      <DatePicker
                        maxDate={watchBusinessValidityEnd ? moment(watchBusinessValidityEnd, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'instant')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="ordered-user-identity-businessValidityStart"
                        data-cy="businessValidityStart"
                        disabled
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="businessValidityEnd"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="ordered-user-identity-businessValidityEnd" className="form-label">
                        {translate('iamdentityApp.orderedUserIdentity.businessValidityEnd')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchBusinessValidityStart ? moment(watchBusinessValidityStart, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'instant')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="ordered-user-identity-businessValidityEnd"
                          data-cy="businessValidityEnd"
                          disabled
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              {/* <ValidatedField
                label={translate('iamdentityApp.orderedUserIdentity.returnedBusinessValidityStart')}
                id="ordered-user-identity-returnedBusinessValidityStart"
                name="returnedBusinessValidityStart"
                data-cy="returnedBusinessValidityStart"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('iamdentityApp.orderedUserIdentity.returnedBusinessValidityEnd')}
                id="ordered-user-identity-returnedBusinessValidityEnd"
                name="returnedBusinessValidityEnd"
                data-cy="returnedBusinessValidityEnd"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              /> */}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.retrieveUserNameFromDependentOrderedUserIdentity')}
                id="ordered-user-identity-retrieveUserNameFromDependentOrderedUserIdentity"
                name="retrieveUserNameFromDependentOrderedUserIdentity"
                data-cy="retrieveUserNameFromDependentOrderedUserIdentity"
                check
                type="checkbox"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.orders" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="order"
                  url={ordersUrl}
                  isDisabled={!!searchParamsObject?.order}
                  queryProps={{
                    name: 'id',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  url={applicationsUrl}
                  isDisabled={!isNew ? true : false}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.employee" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  isDisabled
                  name="employee"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    secondName: 'fullName',
                    type: 'string',
                    criteria: 'nameWithCode',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.dependsOn" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  isDisabled
                  name="dependsOn"
                  url={orderedUserIdentityUrl}
                  queryProps={{
                    name: 'userName',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.operationType')}
                id="ordered-user-identity-operationType"
                disabled={!!searchParamsObject?.operationType || !isNew}
                name="operationType"
                data-cy="operationType"
                type="select"
              >
                {operationTypeValues.map(operationType => (
                  <option value={operationType} key={operationType}>
                    {translate('iamdentityApp.OperationType.' + operationType)}
                  </option>
                ))}
              </ValidatedField>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.dependsOnExisting" />
                </Label>
                <PaginatedReactSelect
                  isDisabled
                  control={control}
                  name="dependsOnExisting"
                  url={userIdentityUrl}
                  queryProps={{
                    name: 'userName',
                    type: 'string',
                  }}
                />
              </FormGroup>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  control: control,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `orderedUserIdentity-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrderedUserIdentityUpdate;
