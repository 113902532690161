import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './profile-specific-permission.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const ProfileSpecificPermissionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { profileSpecificPermissionId: id } = useParams<'profileSpecificPermissionId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_SPECIFIC_PERMISSION));
  }, []);

  const profileSpecificPermissionEntity = useAppSelector(state => state.profileSpecificPermission.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card pad-1">
          <Col md="8">
            <h2 data-cy="profileSpecificPermissionDetailsHeading">
              <Translate contentKey="iamdentityApp.profileSpecificPermission.detail.title">ProfileSpecificPermission</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{profileSpecificPermissionEntity.id}</dd>
              <dt>
                <span id="descriptionExpresion">
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.descriptionExpresion">Description Expresion</Translate>
                </span>
              </dt>
              <dd>
                {profileSpecificPermissionEntity.descriptionExpresion ? (
                  <CompactExpressionBuilder tree={profileSpecificPermissionEntity.descriptionExpresion} />
                ) : null}
              </dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {profileSpecificPermissionEntity.startDate ? (
                  <TextFormat value={profileSpecificPermissionEntity.startDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {profileSpecificPermissionEntity.endDate ? (
                  <TextFormat value={profileSpecificPermissionEntity.endDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="nameExpression">
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.nameExpression">Name Expression</Translate>
                </span>
              </dt>
              <dd>
                {profileSpecificPermissionEntity.nameExpression ? (
                  <CompactExpressionBuilder tree={profileSpecificPermissionEntity.nameExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="permissionDefinitionExpression">
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.permissionDefinitionExpression">
                    Permission Definition Expression
                  </Translate>
                </span>
              </dt>
              <dd>
                {profileSpecificPermissionEntity.permissionDefinitionExpression ? (
                  <CompactExpressionBuilder tree={profileSpecificPermissionEntity.permissionDefinitionExpression} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.profileSpecificPermission.profileUserIdentity">Profile User Identity</Translate>
              </dt>
              <dd>{profileSpecificPermissionEntity.profileUserIdentity ? profileSpecificPermissionEntity.profileUserIdentity.id : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.profileSpecificPermission.permissionDefinition">Permission Definition</Translate>
              </dt>
              <dd>
                {profileSpecificPermissionEntity.permissionDefinition ? profileSpecificPermissionEntity.permissionDefinition.name : ''}
              </dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, profileSpecificPermissionEntity, locale)}</dd>
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{profileSpecificPermissionEntity.createdBy ? profileSpecificPermissionEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {profileSpecificPermissionEntity.createdDate ? (
                  <TextFormat value={profileSpecificPermissionEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{profileSpecificPermissionEntity.lastModifiedBy ? profileSpecificPermissionEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {profileSpecificPermissionEntity.lastModifiedDate ? (
                  <TextFormat
                    value={profileSpecificPermissionEntity.lastModifiedDate}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.PROFILE_SPECIFIC_PERMISSION} />
    </React.Fragment>
  );
};

export default ProfileSpecificPermissionDetail;
