import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './in-user-identity.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';

export const InUserIdentityDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inUserIdentityEntity = useAppSelector(state => state.inUserIdentity.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="inUserIdentityDetailsHeading">
          <Translate contentKey="iamdentityApp.inUserIdentity.detail.title">InUserIdentity</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{inUserIdentityEntity.id}</dd>
          <dt>
            <span id="applicationCode">
              <Translate contentKey="iamdentityApp.inUserIdentity.applicationCode">Application Code</Translate>
            </span>
          </dt>
          <dd>{inUserIdentityEntity.applicationCode}</dd>
          <dt>
            <span id="userBusinessKey">
              <Translate contentKey="iamdentityApp.inUserIdentity.userBusinessKey">User Business Key</Translate>
            </span>
          </dt>
          <dd>{inUserIdentityEntity.userBusinessKey}</dd>
          <dt>
            <span id="userName">
              <Translate contentKey="iamdentityApp.inUserIdentity.userName">User Name</Translate>
            </span>
          </dt>
          <dd>{inUserIdentityEntity.userName}</dd>
          <dt>
            <span id="alternativeUserName">
              <Translate contentKey="iamdentityApp.inUserIdentity.alternativeUserName">Alternative User Name</Translate>
            </span>
          </dt>
          <dd>{inUserIdentityEntity.alternativeUserName}</dd>
          <dt>
            <span id="displayName">
              <Translate contentKey="iamdentityApp.inUserIdentity.displayName">Display Name</Translate>
            </span>
          </dt>
          <dd>{inUserIdentityEntity.displayName}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="iamdentityApp.inUserIdentity.status">Status</Translate>
            </span>
          </dt>
          <dd>{inUserIdentityEntity.status}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="iamdentityApp.inUserIdentity.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {inUserIdentityEntity.startDate ? (
              <TextFormat value={inUserIdentityEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="iamdentityApp.inUserIdentity.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {inUserIdentityEntity.endDate ? (
              <TextFormat value={inUserIdentityEntity.endDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.inUserIdentity.importSession">Import Session</Translate>
          </dt>
          <dd>{inUserIdentityEntity.importSession ? inUserIdentityEntity.importSession.id : ''}</dd>
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              <dt>
                <span id={cad.displayName}>{cad.displayName}</span>
              </dt>
              <dd>{findCustomAttributeValueByKey(cad, inUserIdentityEntity, locale)}</dd>
            </React.Fragment>
          ))}
        </dl>
        <Button id="cancel-save" color="info" onClick={() => navigate(-1)} data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InUserIdentityDetail;
