import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './organization-unit.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const OrganizationUnitDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORGANIZATION_UNIT));
  }, []);

  const organizationUnitEntity = useAppSelector(state => state.organizationUnit.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="organizationUnitDetailsHeading">
              <Translate contentKey="iamdentityApp.organizationUnit.detail.title">OrganizationUnit</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{organizationUnitEntity.id}</dd>
              <dt>
                <span id="name">
                  <Translate contentKey="iamdentityApp.organizationUnit.name">Name</Translate>
                </span>
              </dt>
              <dd>{organizationUnitEntity.name}</dd>
              <dt>
                <span id="shortName">
                  <Translate contentKey="iamdentityApp.organizationUnit.shortName">Short Name</Translate>
                </span>
              </dt>
              <dd>{organizationUnitEntity.shortName}</dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.organizationUnit.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {organizationUnitEntity.startDate ? (
                  <TextFormat value={organizationUnitEntity.startDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.organizationUnit.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {organizationUnitEntity.endDate ? (
                  <TextFormat value={organizationUnitEntity.endDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.organizationUnit.organizationUnitType">Organization Unit Type</Translate>
              </dt>
              <dd>{organizationUnitEntity.organizationUnitType ? organizationUnitEntity.organizationUnitType.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.organizationUnit.location">Location</Translate>
              </dt>
              <dd>{organizationUnitEntity.location ? organizationUnitEntity.location.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.organizationUnit.parentOrganization">Parent Organization</Translate>
              </dt>
              <dd>{organizationUnitEntity.parentOrganization ? organizationUnitEntity.parentOrganization.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.organizationUnit.manager">Manager</Translate>
              </dt>
              <dd>{organizationUnitEntity.manager ? organizationUnitEntity.manager.code : ''}</dd>
              <dt>
                <span id="code">
                  <Translate contentKey="iamdentityApp.organizationUnit.code">Code</Translate>
                </span>
              </dt>
              <dd>{organizationUnitEntity.code ? organizationUnitEntity.code : ''}</dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, organizationUnitEntity, locale)}</dd>
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{organizationUnitEntity.createdBy ? organizationUnitEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {organizationUnitEntity.createdDate ? (
                  <TextFormat value={organizationUnitEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{organizationUnitEntity.lastModifiedBy ? organizationUnitEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {organizationUnitEntity.lastModifiedDate ? (
                  <TextFormat value={organizationUnitEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <div className="btn-group flex-btn-group-container">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              <Button tag={Link} to={`/organization-unit/${organizationUnitEntity.id}/edit`} color="primary">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORGANIZATION_UNIT} />
    </React.Fragment>
  );
};

export default OrganizationUnitDetail;
