import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate, translate } from 'react-jhipster';
import { Link, Route, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';
import { exportEntities, fetchEntityList, getEntities, searchEntities } from './ordered-role.reducer';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { OperationType } from 'app/shared/model/enumerations/operation-type.model';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { IOrderedRole } from 'app/shared/model/ordered-role.model';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import { IOrder } from 'app/shared/model/order.model';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';
import { ModificationLevelType } from 'app/shared/model/enumerations/modification-level-type.model';
import { AppliedOnTypes } from 'app/shared/model/enumerations/applied-on-types.model';

interface IOrderedRoleProps {
  defaultFilter?: string;
  order?: IOrder;
}

export const OrderedRole = ({ defaultFilter, order }: IOrderedRoleProps) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      fetchEntityList({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const orderedRoleList: IOrderedRole[] = useAppSelector(state => state.orderedRole.entities);
  const loading = useAppSelector(state => state.employee.loading || state.customAttributeDefinition.loading);
  const totalItems = useAppSelector(state => state.orderedRole.totalItems);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  /* Enable display of "Add a new Role to Order" button only if the following conditions are met:
- the order STATUS is Draft
<
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "ROLE"
OR
- MODIFICATION LEVEL is "DELETE_EXISTING" and APPLIED ON IS "ROLE"
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "ROLE"
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER"
>
 */
  const creatableRole = (orderInit: IOrder) => {
    let result = false;
    if (orderInit && orderInit.status === OrderStatus.DRAFT) {
      if (
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.DELETE_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER)
      ) {
        result = true;
      }
    }
    return result;
  };

  /* Enable display of the EDIT button only if the following conditions are met:
- the order STATUS is Draft
<
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "USER" and orderedUserIdentity exists and userIdentity is null
OR
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "ROLE" and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "DELETE_EXISTING" and APPLIED ON IS "ROLE" and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "ROLE" and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER" and orderedUserIdentity is null and userIdentity exists
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER" and orderedUserIdentity exists and userIdentity is null
>
 */
  const editableRole = (orderInit: IOrder, orderedRole: IOrderedRole) => {
    let result = false;
    if (orderInit && orderInit.status === OrderStatus.DRAFT) {
      if (
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          orderedRole.orderedUserIdentity &&
          !orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE &&
          !orderedRole.orderedUserIdentity &&
          orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.DELETE_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE &&
          !orderedRole.orderedUserIdentity &&
          orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE &&
          !orderedRole.orderedUserIdentity &&
          orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          !orderedRole.orderedUserIdentity &&
          orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          orderedRole.orderedUserIdentity &&
          !orderedRole.userIdentity)
      ) {
        result = true;
      }
    }
    return result;
  };

  /* Enable display of the DELETE button only if the following conditions are met:
- the order STATUS is Draft
<
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "ROLE" and orderedUserIdentity exists and userIdentity is null and the length of the ORDERED ROLES is greater than 1
OR
- MODIFICATION LEVEL is "ADD_NEW" and APPLIED ON IS "USER" and orderedUserIdentity exists and userIdentity is null
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER" and orderedUserIdentity exists and userIdentity is null
OR
- MODIFICATION LEVEL is "DELETE_EXISTING" and APPLIED ON IS "ROLE" and orderedUserIdentity is null and userIdentity exists and the length of the ORDERED ROLES is greater than 1
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "ROLE" and orderedUserIdentity is null and userIdentity exists and the length of the ORDERED ROLES is greater than 1
OR
- MODIFICATION LEVEL is "MODIFY_EXISTING" and APPLIED ON IS "USER" and orderedUserIdentity is null and userIdentity exists
>
 */
  const deletableRole = (orderInit: IOrder, orderedRole: IOrderedRole) => {
    let result = false;
    const orderedRoleListLength = orderedRoleList.length;
    if (orderInit && orderInit.status === OrderStatus.DRAFT) {
      if (
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE &&
          orderedRoleListLength > 1 &&
          orderedRole.orderedUserIdentity &&
          !orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.ADD_NEW &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          orderedRole.orderedUserIdentity &&
          !orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          orderedRole.orderedUserIdentity &&
          !orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.DELETE_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE &&
          orderedRoleListLength > 1 &&
          !orderedRole.orderedUserIdentity &&
          orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.ROLE &&
          orderedRoleListLength > 1 &&
          !orderedRole.orderedUserIdentity &&
          orderedRole.userIdentity) ||
        (orderInit.orderType.modificationLevel === ModificationLevelType.MODIFY_EXISTING &&
          orderInit.orderType.appliedOn === AppliedOnTypes.USER &&
          !orderedRole.orderedUserIdentity &&
          orderedRole.userIdentity)
      ) {
        result = true;
      }
    }
    return result;
  };

  const getAllEntities = () => {
    const finalQuery = query ? `${defaultFilter}&${query}` : defaultFilter;

    if (search) {
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: finalQuery,
        })
      );
    } else {
      dispatch(
        fetchEntityList({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: finalQuery,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: search,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(fetchEntityList({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_ROLE));
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  return (
    <Card className="jh-card">
      <h2 id="ordered-role-heading" data-cy="OrderedRoleHeading">
        <Translate contentKey="iamdentityApp.orderedRole.home.title">Ordered Roles</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="iamdentityApp.orderedRole.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} /> <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          {defaultFilter && creatableRole(order) && (
            <Link to={`add-new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="iamdentityApp.orderedRole.home.createLabel">Create new Ordered Role</Translate>
            </Link>
          )}
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom table-input-number">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="iamdentityApp.orderedRole.id">ID</Translate> {handleSortIconsTable('id')}
              </th>
              <th className="hand" onClick={sort('order')}>
                <Translate contentKey="iamdentityApp.orderedRole.orders">Order</Translate> {handleSortIconsTable('order')}
              </th>
              <th className="hand" onClick={sort('orderedUserIdentity.application.name')}>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.application">Application</Translate>{' '}
                {handleSortIconsTable('orderedUserIdentity.application.name')}
              </th>
              <th className="hand" onClick={sort('orderedUserIdentity.userName')}>
                <Translate contentKey="iamdentityApp.orderedRole.orderedUserIdentity">Ordered User Identity</Translate>{' '}
                {handleSortIconsTable('orderedUserIdentity.userName')}
              </th>
              <th className="hand" onClick={sort('operationType')}>
                <Translate contentKey="iamdentityApp.orderedRole.operationType">Operation Type</Translate>{' '}
                {handleSortIconsTable('operationType')}
              </th>
              <th className="hand" onClick={sort('roleDefinition.name')}>
                <Translate contentKey="iamdentityApp.orderedRole.roleDefinition">Role Definition</Translate>{' '}
                {handleSortIconsTable('roleDefinition.name')}
              </th>
              <th className="hand" onClick={sort('grantedBy')}>
                <Translate contentKey="iamdentityApp.orderedRole.grantedBy">Granted By</Translate> {handleSortIconsTable('grantedBy')}
              </th>
              <th className="hand" onClick={sort('executionStatus')}>
                <Translate contentKey="iamdentityApp.orderedRole.executionStatus">Execution Status</Translate>{' '}
                {handleSortIconsTable('executionStatus')}
              </th>
              <th className="hand" onClick={sort('scheduleTime')}>
                <Translate contentKey="iamdentityApp.orderedRole.scheduleTime">Schedule Time</Translate>{' '}
                {handleSortIconsTable('scheduleTime')}
              </th>
              <th className="hand" onClick={sort('executionStartTime')}>
                <Translate contentKey="iamdentityApp.orderedRole.executionStartTime">Execution Start Time</Translate>{' '}
                {handleSortIconsTable('executionStartTime')}
              </th>
              <th className="hand" onClick={sort('executionEndTime')}>
                <Translate contentKey="iamdentityApp.orderedRole.executionEndTime">Execution End Time</Translate>{' '}
                {handleSortIconsTable('executionEndTime')}
              </th>
              <th className="hand" onClick={sort('userIdentity.userName')}>
                <Translate contentKey="iamdentityApp.orderedRole.userIdentity">User Identity</Translate>{' '}
                {handleSortIconsTable('userIdentity.userName')}
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th key={cad.id}>{cad.description}</th>
              ))}
              <th className="text-right">
                <span>
                  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>
                    <FontAwesomeIcon icon="magnifying-glass" />
                  </Button>
                </span>
                <UncontrolledTooltip placement="top" target="toggle-filter">
                  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>
                </UncontrolledTooltip>
              </th>
            </tr>
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'ignore',
                  },
                  {
                    name: 'orderId',
                    type: 'number',
                  },
                  {
                    name: 'application',
                    type: 'string',
                  },
                  {
                    name: 'orderedUserIdentity',
                    type: 'string',
                  },
                  {
                    name: 'operationType',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OperationType).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OperationType.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'roleDefinition',
                    type: 'string',
                  },
                  {
                    name: 'grantedBy',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(PermissionGrantLevel).map(value => ({
                        value,
                        label: translate(`iamdentityApp.PermissionGrantLevel.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'executionStatus',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OrderExecutionStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OrderExecutionStatus.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'scheduleTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionStartTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionEndTime',
                    type: 'instant',
                  },
                  {
                    name: 'userIdentity',
                    type: 'string',
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
          </thead>
          <tbody>
            {orderedRoleList.map((orderedRole, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/ordered-role/${orderedRole.id}`} color="link" size="sm">
                    {orderedRole.id}
                  </Button>
                </td>
                <td>{orderedRole.order ? <Link to={`/orders/${orderedRole.order.id}`}>{orderedRole.order.id}</Link> : ''}</td>
                <td>
                  {orderedRole?.orderedUserIdentity?.application ? (
                    <Link to={`/application/${orderedRole?.orderedUserIdentity?.application.id}`}>
                      {orderedRole?.orderedUserIdentity?.application.name}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {orderedRole.orderedUserIdentity ? (
                    <Link to={`/ordered-user-identity/${orderedRole.orderedUserIdentity.id}`}>
                      {orderedRole.orderedUserIdentity.userName}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{translate(`iamdentityApp.OperationType.${orderedRole.operationType}`)}</td>
                <td>
                  {orderedRole.roleDefinition ? (
                    <Link to={`/role-definition/${orderedRole.roleDefinition.id}`}>{orderedRole.roleDefinition.name}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{translate(`iamdentityApp.PermissionGrantLevel.${orderedRole.grantedBy}`)}</td>
                <td>{translate(`iamdentityApp.OrderExecutionStatus.${orderedRole.executionStatus}`)}</td>
                <td>
                  {orderedRole.scheduleTime ? (
                    <TextFormat type="date" value={orderedRole.scheduleTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orderedRole.executionStartTime ? (
                    <TextFormat type="date" value={orderedRole.executionStartTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orderedRole.executionEndTime ? (
                    <TextFormat type="date" value={orderedRole.executionEndTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orderedRole.userIdentity ? (
                    <Link to={`/user-identity/${orderedRole.userIdentity.id}`}>{orderedRole.userIdentity.userName}</Link>
                  ) : (
                    ''
                  )}
                </td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>{findCustomAttributeValueByKey(cad, orderedRole, locale)}</td>
                ))}
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    {defaultFilter ? (
                      <React.Fragment>
                        <Button tag={Link} to={`view/${orderedRole.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        {editableRole(order, orderedRole) ? (
                          <Button tag={Link} to={`update/${orderedRole.id}`} color="primary" size="sm" data-cy="entityEditButton">
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                        ) : null}
                        {deletableRole(order, orderedRole) ? (
                          <Button tag={Link} to={`delete/${orderedRole.id}`} color="danger" size="sm" data-cy="entityDeleteButton">
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <Button tag={Link} to={`/ordered-role/${orderedRole.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!orderedRoleList || orderedRoleList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.orderedRole.home.notFound">No Ordered Roles found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={orderedRoleList && orderedRoleList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default OrderedRole;
