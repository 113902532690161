/* eslint-disable complexity */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, FormGroup, Input, Label, Row, Spinner, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { apiUrl as applicationsUrl } from 'app/entities/application/application.reducer';
import { apiUrl as employeesUrl } from 'app/entities/employee/employee.reducer';
import { apiUrl as jobDefinitionsUrl } from 'app/entities/job-definition/job-definition.reducer';
import { apiUrl as orderTypesUrl } from 'app/entities/order-type/order-type.reducer';
import { apiUrl as organizationUnitsUrl } from 'app/entities/organization-unit/organization-unit.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { IApplication } from 'app/shared/model/application.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { AppliedOnTypes } from 'app/shared/model/enumerations/applied-on-types.model';
import { IOrderType } from 'app/shared/model/order-type.model';
import { ICreateOrder, IOrder } from 'app/shared/model/order.model';
import { IOrderedGroup } from 'app/shared/model/ordered-group.model';
import { IOrderedRole } from 'app/shared/model/ordered-role.model';
import { IOrderedSpecificPermission } from 'app/shared/model/ordered-specific-permission.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { renderAndRegisterCustomAttributeField, renderCustomAttributeField } from 'app/shared/util/component-utils';
import { convertDateTimeToServer, isDateInTheFuture } from 'app/shared/util/date-utils';
import { addCustomPropertiesToCustomAttributesMap } from 'app/shared/util/entity-utils';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import {
  createEntity,
  getEntity,
  getOrderedRoleCustomAttributes,
  getOrderedRoleGroupCustomAttributes,
  getOrderedSpecificPermissionCustomAttributes,
  getOrderedUserIdentityCustomAttributes,
  reset as ordersReset,
} from './orders.reducer';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { ModificationLevelType } from 'app/shared/model/enumerations/modification-level-type.model';
import { IRoleDefinition } from 'app/shared/model/role-definition.model';
import { IRoleGroup } from 'app/shared/model/role-group.model';
import { IPermissionDefinition } from 'app/shared/model/permission-definition.model';
import { CustomAttributeType } from 'app/shared/model/enumerations/custom-attribute-type.model';
import axios from 'axios';
import { QueryClient, useQuery } from 'react-query';
import { IEmployeeDelegation } from 'app/shared/model/employee-delegation.model';
import { IUserIdentityRole } from 'app/shared/model/user-identity-role.model';
import { IUserGroup } from 'app/shared/model/user-group.model';
import { ISpecificPermission } from 'app/shared/model/specific-permission.model';

import DatePicker from 'react-datepicker';
import ro from 'date-fns/locale/ro';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { renderToString } from 'react-dom/server';
import { Controller } from 'react-hook-form';

const ORDER_TYPES_LIST_URL = 'api/order-create/order-type-list';
const APPLICATION_LIST_URL = 'api/order-create/application-list';
const USER_IDENTITYS_LIST_URL = 'api/order-create/user-identity-list';
const ROLE_DEFINITIONS_LIST_URL = 'api/order-create/role-definition-list';
const ROLE_DEFINITIONS_LIST_BY_APP_URL = 'api/order-create/role-definition-by-app-list';
const GROUP_DEFINITIONS_LIST_URL = 'api/order-create/group-definition-list';
const PERMISSIONS_DEFINITIONS_LIST_URL = 'api/order-create/permission-definition-list';
const ORGANIZATION_UNIT_URL = 'api/order-create/organization-unit-list';
const EMPLOYEE_DELEGATION_URL = 'api/order-create/employee-delegation-list';
const ALL_GROUP_DEFINITIONS_LIST = 'api/order-create/all-group-definition-list';
const ALL_PERMISSIONS_DEFINITIONS_LIST = 'api/order-create/all-permission-definition-list';

enum DateNextChangePasswordOptions {
  inADay = 'inADay',
  inAWeek = 'inAWeek',
  inAMonth = 'inAMonth',
  after3Months = 'after3Months',
  after6Months = 'after6Months',
  inAYear = 'inAYear',
}

function calculateDateNextChangePassword(value: DateNextChangePasswordOptions) {
  switch (value) {
    case DateNextChangePasswordOptions.inADay:
      return moment().add(1, 'days');
    case DateNextChangePasswordOptions.inAWeek:
      return moment().add(1, 'weeks');
    case DateNextChangePasswordOptions.inAMonth:
      return moment().add(1, 'months');
    case DateNextChangePasswordOptions.after3Months:
      return moment().add(3, 'months');
    case DateNextChangePasswordOptions.after6Months:
      return moment().add(6, 'months');
    case DateNextChangePasswordOptions.inAYear:
      return moment().add(1, 'years');
    default:
      return moment().add(1, 'days');
  }
}

export const OrdersUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { orderId: id } = useParams<'orderId'>();
  const isNew = id === undefined;

  const [selectedOrderType, setSelectedOrderType] = useState<IOrderType>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployee>(null);
  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);
  const [cumulatedDelegation, setCumulatedDelegation] = useState(false);

  const ordersEntity: IOrder = useAppSelector(state => state.orders.entity);
  const orderedRoleCustomAttributes: CustomAttributeDtoKey[] = useAppSelector(state => state.orders.orderedRoleCustomAttributes);
  const orderedRoleGroupCustomAttributes: CustomAttributeDtoKey[] = useAppSelector(state => state.orders.orderedRoleGroupCustomAttributes);

  const orderedSpecificPermissionCustomAttributes: CustomAttributeDtoKey[] = useAppSelector(
    state => state.orders.orderedSpecificPermissionCustomAttributes
  );
  const orderedUserIdentityCustomAttributes: CustomAttributeDtoKey[] = useAppSelector(
    state => state.orders.orderedUserIdentityCustomAttributes
  );
  const loading = useAppSelector(state => state.orders.loading);
  const updating = useAppSelector(state => state.orders.updating);
  const updateSuccess = useAppSelector(state => state.orders.updateSuccess);

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    setValue,
    reset: resetForm,
    formState: { errors, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  // standard refs
  const { ref: employeeRef, ...employeeProps } = register('employee', { required: true });
  const { ref: orderTypeRef, ...orderTypeProps } = register('orderType', { required: true });
  const { ref: scheduleTimeRef, ...scheduleTimeProps } = register('scheduleTime', {
    // validate: isDateInTheFuture,
  });

  // Application ref
  const { ref: applicationRef, ...applicationProps } = register('application');

  // USER refs
  const { ref: userNameRef, ...userNameProps } = register('userName');
  const { ref: alternativeUserNameRef, ...alternativeUserNameProps } = register('alternativeUserName');
  const { ref: associatedEmailAddressRef, ...associatedEmailAddressProps } = register('associatedEmailAddress');
  const { ref: displayNameRef, ...displayNameProps } = register('displayName');
  const { ref: initialPasswordRef, ...initialPasswordProps } = register('initialPassword');
  const { ref: mustChangePasswordOnFirstLoginRef, ...mustChangePasswordOnFirstLoginProps } = register('mustChangePasswordOnFirstLogin');
  const { ref: dateNextChangePasswordRef, ...dateNextChangePasswordProps } = register('dateNextChangePassword');

  // ROLE refs
  const { ref: roleDefinitionRef, ...roleDefinitionProps } = register('roleDefinition');

  // GROUP refs
  const { ref: roleGroupRef, ...roleGroupProps } = register('roleGroup');

  // PERMISSION refs
  const { ref: permissionDefinitionRef, ...permissionDefinitionProps } = register('permissionDefinition');

  // POSITION refs
  const { ref: organizationUnitRef, ...organizationUnitProps } = register('organizationUnit');
  const { ref: jobDefinitionRef, ...jobDefinitionProps } = register('jobDefinition');

  // TEMPORARY refs
  const { ref: temporaryRef, ...temporaryProps } = register('temporary');
  const { ref: orderValidUntilRef, ...orderValidUntilProps } = register('orderValidUntil');

  // Cumulated refs
  const { ref: cumulatedRef, ...cumulatedProps } = register('cumulated');

  // allApps refs
  const { ref: allAppsRef, ...allAppsProps } = register('allApps');

  // addUserException refs
  const { ref: addUserExceptionRef, ...addUserExceptionProps } = register('addUserException');

  // watch
  const watchOrderType: IOrderType = watch('orderType');
  const watchEmployee: IEmployee = watch('employee');
  // const watchApplication: IApplication = watch('application');
  const watchApplication = watch('application');
  const watchTemporary = watch('temporary');
  const watchScheduleTime = watch('scheduleTime');
  const watchUserIdentity: IUserIdentity = watch('userIdentity');
  const watchRoleDefObj = watch('roleDefinition');
  const watchRoleGrObj = watch('roleGroup');
  const watchPermDefObj = watch('permissionDefinition');
  const watchAllApps = watch('allApps');
  const watchAddUserException = watch('addUserException');

  useEffect(() => {
    if (isNew) {
      dispatch(ordersReset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getOrderedRoleCustomAttributes());
    dispatch(getOrderedRoleGroupCustomAttributes());
    dispatch(getOrderedSpecificPermissionCustomAttributes());
    dispatch(getOrderedUserIdentityCustomAttributes());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (watchApplication) {
      setSelectedApplication(watchApplication);
      resetField('roleGroup');
      resetField('permissionDefinition');
      resetField('roleDefinition');
    } else {
      setSelectedApplication(null);
    }
  }, [watchApplication]);

  useEffect(() => {
    if (watchOrderType) {
      setValue('addUserException', false);
      setSelectedOrderType(watchOrderType);
    } else {
      setValue('addUserException', false);
      setSelectedOrderType(null);
    }
  }, [watchOrderType]);

  useEffect(() => {
    if (watchEmployee) {
      if (selectedOrderType && showPositionFields()) {
        setValue('organizationUnit', watchEmployee.organizationUnit?.id);
        setValue('jobDefinition', watchEmployee.job?.id);
      }
      setSelectedEmployee(watchEmployee);
    } else {
      setSelectedEmployee(null);
      resetField('orderType');
      setValue('orderType', null);
      if (selectedOrderType && showPositionFields()) {
        resetField('organizationUnit');
        resetField('jobDefinition');
      }
    }
  }, [watchEmployee]);

  useEffect(() => {
    if (watchTemporary) {
      register('orderValidUntil', { required: true });
    } else {
      resetField('orderValidUntil');
      register('orderValidUntil', { required: false });
    }
  }, [watchTemporary]);

  //populate value for modify user attributes
  useEffect(() => {
    if (
      selectedOrderType?.appliedOn == AppliedOnTypes.USER &&
      selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING &&
      watchUserIdentity
    ) {
      setValue('userName', watchUserIdentity.userName);
      setValue('alternativeUserName', watchUserIdentity.alternativeUserName);
      setValue('associatedEmailAddress', watchUserIdentity.associatedEmailAddress);
      setValue('displayName', watchUserIdentity.displayName);
      setValue('initialPassword', watchUserIdentity.initialPassword);
      setValue('mustChangePasswordOnFirstLogin', watchUserIdentity.mustChangePasswordOnFirstLogin);
      setValue('dateNextChangePassword', watchUserIdentity.dateNextChangePassword);
    }
  }, [watchUserIdentity]);

  useEffect(() => {
    if (watchAllApps) {
      resetField('application');
      resetField('initialPassword');
      register('application', { required: false });
    } else {
      register('application', { required: true });
      resetField('initialPassword');
    }
  }, [watchAllApps]);

  useEffect(() => {
    if (watchAddUserException) {
      resetField('application');
      setValue('application', null);
    } else {
      resetField('application');
      setValue('application', null);
    }
  }, [watchAddUserException]);

  const showAllAppsField = () => {
    return selectedOrderType?.appliedOn == AppliedOnTypes.USER && selectedOrderType?.modificationLevel == ModificationLevelType.PASSWORD;
  };

  const showAddUserExceptionField = () => {
    return (
      selectedOrderType?.appliedOn == AppliedOnTypes.USER && selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION
    );
  };

  const showApplicationField = () => {
    return (
      selectedOrderType?.appliedOn == AppliedOnTypes.USER ||
      selectedOrderType?.appliedOn == AppliedOnTypes.ROLE ||
      selectedOrderType?.appliedOn == AppliedOnTypes.GROUP ||
      selectedOrderType?.appliedOn == AppliedOnTypes.PERMISSION
    );
  };

  const showTemporaryField = () => {
    if (selectedOrderType?.revertingOrderType) {
      return true;
    }
    return false;
  };

  const showUserFields = () => {
    return (
      selectedOrderType?.appliedOn == AppliedOnTypes.USER &&
      watchApplication &&
      (selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW ||
        (watchUserIdentity && selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING) ||
        (watchUserIdentity && selectedOrderType?.modificationLevel == ModificationLevelType.PASSWORD))
    );
  };

  const showUserIdentity = () => {
    return (
      (selectedOrderType?.appliedOn == AppliedOnTypes.ROLE &&
        (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION) &&
        watchApplication) ||
      (selectedOrderType?.appliedOn == AppliedOnTypes.GROUP &&
        (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION) &&
        watchApplication) ||
      (selectedOrderType?.appliedOn == AppliedOnTypes.PERMISSION &&
        (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION) &&
        watchApplication) ||
      (selectedOrderType?.appliedOn == AppliedOnTypes.USER &&
        (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.PASSWORD ||
          selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION ||
          (selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION && !watchAddUserException)) &&
        watchApplication)
    );
  };

  const showRoleFields = () => {
    return (
      (selectedOrderType?.appliedOn == AppliedOnTypes.ROLE && watchUserIdentity) ||
      (showUserFields() && !!selectedApplication?.roleRequired)
    );
  };

  const showGroupFields = () => {
    return selectedOrderType?.appliedOn == AppliedOnTypes.GROUP && watchUserIdentity;
  };

  const showPermissionFields = () => {
    return selectedOrderType?.appliedOn == AppliedOnTypes.PERMISSION && watchUserIdentity;
  };

  const showPositionFields = () => {
    return selectedOrderType?.appliedOn == AppliedOnTypes.POSITION && selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW;
  };
  const showEmployeeDelegation = () => {
    return (
      selectedOrderType?.appliedOn == AppliedOnTypes.POSITION &&
      selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING
    );
  };

  const selectEmployeeDelegationSource = (employeeId?: number, scheduleTime: any = null) => {
    if (
      selectedOrderType?.appliedOn == AppliedOnTypes.POSITION &&
      selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING
    ) {
      if (scheduleTime) {
        const event = new Date(scheduleTime);
        return (
          EMPLOYEE_DELEGATION_URL +
          '?employeeId= ' +
          employeeId +
          '&scheduleTime=' +
          event.toISOString() +
          '&cumulated=' +
          cumulatedDelegation
        );
      } else {
        return EMPLOYEE_DELEGATION_URL + '?employeeId= ' + employeeId + '&scheduleTime=' + '' + '&cumulated=' + cumulatedDelegation;
      }
    }
  };

  const selectOrderTypeSource = (employeeId?: number) => {
    return `${ORDER_TYPES_LIST_URL}?employeeId=${employeeId ? employeeId : 0}`;
  };

  const selectApplicationSource = (
    employeeId?: number,
    scheduleTime: any = null,
    forStandardPolicy?: boolean,
    temporary: boolean = false
  ) => {
    if (
      ((selectedOrderType?.appliedOn == AppliedOnTypes.ROLE ||
        selectedOrderType?.appliedOn == AppliedOnTypes.GROUP ||
        selectedOrderType?.appliedOn == AppliedOnTypes.PERMISSION) &&
        (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW)) ||
      (selectedOrderType?.appliedOn == AppliedOnTypes.USER &&
        (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.PASSWORD))
    ) {
      if (scheduleTime) {
        const event = new Date(scheduleTime);
        return (
          APPLICATION_LIST_URL +
          '?employeeId=' +
          employeeId +
          '&scheduleTime=' +
          event.toISOString() +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&temporary=' +
          temporary +
          '&appliedOn=' +
          selectedOrderType?.appliedOn +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel +
          '&orderTypeId=' +
          selectedOrderType?.id
        );
      } else {
        return (
          APPLICATION_LIST_URL +
          '?employeeId=' +
          employeeId +
          '&scheduleTime=' +
          '' +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&temporary=' +
          temporary +
          '&appliedOn=' +
          selectedOrderType?.appliedOn +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel +
          '&orderTypeId=' +
          selectedOrderType?.id
        );
      }
    } else {
      return applicationsUrl;
    }
  };
  const selectUserIdentitySource = (
    employeeId?: number,
    applicationId?: number,
    scheduleTime: any = null,
    forStandardPolicy?: boolean,
    temporary: boolean = false
  ) => {
    if (
      ((selectedOrderType?.appliedOn == AppliedOnTypes.ROLE ||
        selectedOrderType?.appliedOn == AppliedOnTypes.GROUP ||
        selectedOrderType?.appliedOn == AppliedOnTypes.PERMISSION) &&
        (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW ||
          selectedOrderType?.modificationLevel == ModificationLevelType.PASSWORD ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION)) ||
      (selectedOrderType?.appliedOn == AppliedOnTypes.USER &&
        (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW ||
          selectedOrderType?.modificationLevel == ModificationLevelType.PASSWORD ||
          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
          selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION))
    ) {
      if (scheduleTime) {
        const event = new Date(scheduleTime);
        return (
          USER_IDENTITYS_LIST_URL +
          '?employeeId=' +
          employeeId +
          '&applicationId=' +
          applicationId +
          '&scheduleTime=' +
          event.toISOString() +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&temporary=' +
          temporary +
          '&appliedOn=' +
          selectedOrderType?.appliedOn +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel
        );
      } else {
        return (
          USER_IDENTITYS_LIST_URL +
          '?employeeId=' +
          employeeId +
          '&applicationId=' +
          applicationId +
          '&scheduleTime=' +
          '' +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&temporary=' +
          temporary +
          '&appliedOn=' +
          selectedOrderType?.appliedOn +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel
        );
      }
    }
  };
  const selectRoleDefinitionSource = (
    userIdentityId?: number,
    applicationId?: number,
    scheduleTime: any = null,
    forStandardPolicy?: boolean,
    temporary: boolean = false
  ) => {
    if (
      selectedOrderType?.appliedOn == AppliedOnTypes.ROLE &&
      (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
        selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
        selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
        selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION)
    ) {
      if (scheduleTime) {
        const event = new Date(scheduleTime);
        return (
          ROLE_DEFINITIONS_LIST_URL +
          '?userIdentityId=' +
          userIdentityId +
          '&scheduleTime=' +
          event.toISOString() +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel +
          '&temporary=' +
          temporary
        );
      } else {
        return (
          ROLE_DEFINITIONS_LIST_URL +
          '?userIdentityId=' +
          userIdentityId +
          '&scheduleTime=' +
          '' +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel +
          '&temporary=' +
          temporary
        );
      }
    }
    if (selectedOrderType?.appliedOn == AppliedOnTypes.ROLE && selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW) {
      return ROLE_DEFINITIONS_LIST_BY_APP_URL + '?applicationId=' + applicationId + '&userIdentityId=' + userIdentityId;
    }
  };
  const selectGroupDefinionSource = (
    userIdentityId?: number,
    scheduleTime: any = null,
    forStandardPolicy?: boolean,
    temporary: boolean = false
  ) => {
    if (
      selectedOrderType?.appliedOn == AppliedOnTypes.GROUP &&
      (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
        selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
        selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
        selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION)
    ) {
      if (scheduleTime) {
        const event = new Date(scheduleTime);
        return (
          GROUP_DEFINITIONS_LIST_URL +
          '?userIdentityId=' +
          userIdentityId +
          '&scheduleTime=' +
          event.toISOString() +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel +
          '&temporary=' +
          temporary
        );
      } else {
        return (
          GROUP_DEFINITIONS_LIST_URL +
          '?userIdentityId=' +
          userIdentityId +
          '&scheduleTime=' +
          '' +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel +
          '&temporary=' +
          temporary
        );
      }
    }
    if (selectedOrderType?.appliedOn == AppliedOnTypes.GROUP && selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW) {
      return ALL_GROUP_DEFINITIONS_LIST + '?userIdentityId=' + (userIdentityId ? userIdentityId : '') + '&scheduleTime=' + '';
    }
  };
  const selectPermDefinionSource = (
    userIdentityId?: number,
    scheduleTime: any = null,
    forStandardPolicy?: boolean,
    temporary: boolean = false
  ) => {
    if (
      selectedOrderType?.appliedOn == AppliedOnTypes.PERMISSION &&
      (selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXISTING ||
        selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
        selectedOrderType?.modificationLevel == ModificationLevelType.ADD_EXCEPTION ||
        selectedOrderType?.modificationLevel == ModificationLevelType.DELETE_EXCEPTION)
    ) {
      if (scheduleTime) {
        const event = new Date(scheduleTime);
        return (
          PERMISSIONS_DEFINITIONS_LIST_URL +
          '?userIdentityId=' +
          userIdentityId +
          '&scheduleTime=' +
          event.toISOString() +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel +
          '&temporary=' +
          temporary
        );
      } else {
        return (
          PERMISSIONS_DEFINITIONS_LIST_URL +
          '?userIdentityId=' +
          userIdentityId +
          '&scheduleTime=' +
          '' +
          '&forStandardPolicy=' +
          forStandardPolicy +
          '&modificationLevelType=' +
          selectedOrderType?.modificationLevel +
          '&temporary=' +
          temporary
        );
      }
    }
    if (
      selectedOrderType?.appliedOn == AppliedOnTypes.PERMISSION &&
      selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW
    ) {
      return (
        ALL_PERMISSIONS_DEFINITIONS_LIST +
        '?userIdentityId=' +
        (userIdentityId ? userIdentityId : '') +
        '&scheduleTime=' +
        (scheduleTime ? scheduleTime : '')
      );
    }
  };

  const handleCumulatedDelegation = () => {
    setCumulatedDelegation(!cumulatedDelegation);
    setValue('employeeDelegation', null);
  };

  const resetValidUntilAndTemporary = () => {
    resetField('temporary');
    setValue('temporary', null);
    resetField('orderValidUntil');
    setValue('orderValidUntil', null);
  };

  useEffect(() => {
    if (showApplicationField()) {
      register('application', { required: true });
      setValue('allApps', false);
    } else {
      resetField('application');
      register('application', { required: false });
    }
    if (showUserIdentity()) {
      register('userName', { required: true });
    } else {
      resetField('userName');
    }
    if (showUserFields()) {
      register('userName', { required: true });
      // register('alternativeUserName', { required: false });
      // register('associatedEmailAddress', { required: false });
      // register('displayName', { required: false });
      // register('initialPassword', { required: false });
      // register('dateNextChangePassword', { required: false });
    } else {
      resetField('userName');
      resetField('alternativeUserName');
      resetField('associatedEmailAddress');
      resetField('displayName');
      resetField('initialPassword');
      resetField('dateNextChangePassword');
      register('userName', { required: false });
      // register('alternativeUserName', { required: false });
      // register('associatedEmailAddress', { required: false });
      // register('displayName', { required: false });
      // register('initialPassword', { required: false });
      // register('dateNextChangePassword', { required: false });
      orderedUserIdentityCustomAttributes?.forEach(cad => {
        resetField(cad.displayName);
      });
      // unregister(["application"]);
    }

    if (showRoleFields()) {
      register('roleDefinition', { required: true });
    } else {
      resetField('roleDefinition');
      register('roleDefinition', { required: false });
      orderedRoleCustomAttributes?.forEach(cad => {
        resetField(cad.displayName);
      });
    }

    if (showGroupFields()) {
      register('roleGroup', { required: true });
    } else {
      resetField('roleGroup');
      register('roleGroup', { required: false });
      orderedRoleGroupCustomAttributes?.forEach(cad => {
        resetField(cad.displayName);
      });
    }

    if (showEmployeeDelegation()) {
      register('employeeDelegation', { required: true });
    } else {
      resetField('employeeDelegation');
    }
    if (showPositionFields()) {
      register('organizationUnit', { required: true });
      register('jobDefinition', { required: true });
      if (selectedEmployee) {
        setValue('organizationUnit', selectedEmployee.organizationUnit?.id);
        setValue('jobDefinition', selectedEmployee.job?.id);
      }
    } else {
      resetField('organizationUnit');
      resetField('jobDefinition');
      register('organizationUnit', { required: false });
      register('jobDefinition', { required: false });
    }

    resetField('application');
    setValue('application', null);
    resetValidUntilAndTemporary();
  }, [selectedOrderType, watchEmployee]);

  useEffect(() => {
    if (watchEmployee) {
      resetField('orderType');
      setValue('orderType', null);
      resetValidUntilAndTemporary();
    }
  }, [watchEmployee]);

  useEffect(() => {
    if (watchOrderType) {
      resetField('application');
      setValue('application', null);
      resetValidUntilAndTemporary();
    }
  }, [watchOrderType]);

  useEffect(() => {
    resetField('userIdentity');
    setValue('userIdentity', null);
    resetField('roleDefinition');
    setValue('roleDefinition', null);
    resetField('permissionDefinition');
    setValue('permissionDefinition', null);
    resetValidUntilAndTemporary();
  }, [watchApplication]);

  useEffect(() => {
    if (watchScheduleTime) {
      resetField('orderType');
      setValue('orderType', null);
      resetField('application');
      setValue('application', null);
      resetValidUntilAndTemporary();
      setSchDateShowOrderValidUntil(false);
    } else {
      resetValidUntilAndTemporary();
      setSchDateShowOrderValidUntil(false);
    }
  }, [watchScheduleTime]);

  useEffect(() => {
    if (showPermissionFields() && watchApplication && watchUserIdentity?.userName) {
      register('permissionDefinition', { required: true });
    } else {
      resetField('permissionDefinition');
      register('permissionDefinition', { required: false });
      orderedSpecificPermissionCustomAttributes?.forEach(cad => {
        resetField(cad.displayName);
      });
    }
  }, [watchApplication, watchUserIdentity]);

  const handleClose = () => {
    navigate(`/orders/${ordersEntity?.id}`, { replace: true });
  };

  const saveEntity = values => {
    const employee: IEmployee = {
      id: values.employee.id,
      organizationUnit: values?.organizationUnit?.id ? { id: values.organizationUnit.id } : undefined,
      job: values?.jobDefinition?.id ? { id: values.jobDefinition.id } : undefined,
    };

    const orderEntity: IOrder = {
      employee,
      orderType: {
        id: values.orderType?.id,
      },
      scheduleTime: values.scheduleTime
        ? values.scheduleTime < new Date()
          ? convertDateTimeToServer(new Date())
          : convertDateTimeToServer(values.scheduleTime)
        : null,
      temporary: values?.temporary ?? false,
      orderValidUntil: values?.temporary
        ? values.orderValidUntil < values.scheduleTime
          ? convertDateTimeToServer(values.scheduleTime)
          : convertDateTimeToServer(values.orderValidUntil)
        : undefined,
    };

    const orderedUserIdentityEntity: IOrderedUserIdentity = {
      ...addCustomPropertiesToCustomAttributesMap({}, values, orderedUserIdentityCustomAttributes, true),
      userName: values?.userName ?? undefined,
      alternativeUserName: values?.alternativeUserName ?? undefined,
      associatedEmailAddress: values?.associatedEmailAddress ?? undefined,
      displayName: values?.displayName ?? undefined,
      initialPassword: values?.initialPassword ?? undefined,
      mustChangePasswordOnFirstLogin: values?.mustChangePasswordOnFirstLogin ?? undefined,
      dateNextChangePassword: values?.dateNextChangePassword
        ? calculateDateNextChangePassword(values?.dateNextChangePassword).format(APP_LOCAL_DATETIME_FORMAT)
        : undefined,
      application: values?.application?.id ? { id: values.application.id } : undefined,
      relatedUserIdentity: values?.userIdentity?.id ? { id: values.userIdentity.id } : undefined,
    };

    const orderedRoleEntity: IOrderedRole = {
      ...addCustomPropertiesToCustomAttributesMap({}, values, orderedRoleCustomAttributes, true),
      roleDefinition: values?.roleDefinition?.id ? { id: values.roleDefinition.id } : undefined,
      application: values?.application?.id ? { id: values.application.id } : undefined,
      relatedUserRole: watchRoleDefObj?.relatedUserRole?.id ? { id: watchRoleDefObj.relatedUserRole.id } : undefined,
    };

    const orderedGroupEntity: IOrderedGroup = {
      ...addCustomPropertiesToCustomAttributesMap({}, values, orderedRoleGroupCustomAttributes, true),
      roleGroup: values?.roleGroup?.id ? { id: values.roleGroup.id } : undefined,
      application: values?.application?.id ? { id: values.application.id } : undefined,
      relatedUserGroup: watchRoleGrObj?.relatedUserGroup?.id ? { id: watchRoleGrObj.relatedUserGroup.id } : undefined,
    };

    const orderedSpecificPermissionEntity: IOrderedSpecificPermission = {
      ...addCustomPropertiesToCustomAttributesMap({}, values, orderedSpecificPermissionCustomAttributes, true),
      permissionDefinition: values?.permissionDefinition?.id ? { id: values.permissionDefinition.id } : undefined,
      application: values?.application?.id ? { id: values.application.id } : undefined,
      relatedSpecificPermission: watchPermDefObj?.relatedSpecificPermission?.id
        ? { id: watchPermDefObj.relatedSpecificPermission.id }
        : undefined,
    };

    const userIdentityEntity: IUserIdentity = values?.userIdentity ? { id: values.userIdentity.id } : undefined;
    const userIdentityRoleEntity: IUserIdentityRole = values?.roleDefinition ? { id: values.roleDefinition.id } : undefined;
    const userGroupEntity: IUserGroup = values?.roleGroup ? { id: values.roleGroup.id } : undefined;
    const specificPermissionEntity: ISpecificPermission = values?.permissionDefinition ? { id: values.permissionDefinition.id } : undefined;

    const employeeDelegationEntity: IEmployeeDelegation = {
      id: values?.employeeDelegation?.id ? values.employeeDelegation.id : undefined,
      organizationUnit: values?.organizationUnit?.id ? { id: values.organizationUnit.id } : undefined,
      job: values?.jobDefinition?.id ? { id: values.jobDefinition.id } : undefined,
      validFrom: values?.scheduleTimeRef ? values.scheduleTimeRef : moment().toISOString(),
      validTo: null,
      cumulated: showPositionFields() ? values?.cumulated : null,
    };

    const entity: ICreateOrder = {
      order: orderEntity,
      orderedGroup: showGroupFields() ? orderedGroupEntity : undefined,
      orderedRole: showRoleFields() ? orderedRoleEntity : undefined,
      orderedSpecificPermission: showPermissionFields() ? orderedSpecificPermissionEntity : undefined,
      orderedUserIdentity: showUserFields() || showAddUserExceptionField() ? orderedUserIdentityEntity : undefined,
      userIdentity: userIdentityEntity,
      userIdentityRole: userIdentityRoleEntity,
      userGroup: userGroupEntity,
      specificPermission: specificPermissionEntity,
      employeeDelegation: showPositionFields() || showEmployeeDelegation() ? employeeDelegationEntity : undefined,
      allApps: showAllAppsField() ? values?.allApps : null,
      allAppsPassword: showAllAppsField() && watchAllApps ? values?.initialPassword : null,
    };
    if (isNew) {
      dispatch(createEntity(entity));
    }
  };
  const startDatePredefinedQuery = scheduleTime => {
    const startDate =
      scheduleTime && moment(scheduleTime).isAfter(moment().toISOString())
        ? `startDate.lessThanOrEqual=${moment(scheduleTime).toISOString()}`
        : `startDate.lessThanOrEqual=${moment().toISOString()}`;
    return startDate;
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const [scheduleTimeInit, setSchDate] = useState(new Date());
  const [showDate, setSchDateShow] = useState(false);
  const showSchDate = () => {
    setSchDateShow(true);
  };
  const [orderValidUntilInit, setSchDateOrderValidUntil] = useState(new Date());
  const [showDateOrderValidUntil, setSchDateShowOrderValidUntil] = useState(false);
  const showSchDateOrderValidUntil = () => {
    setSchDateShowOrderValidUntil(true);
  };
  const translateTimeElement = <Translate contentKey="global.time">Time</Translate>;
  const convertTimeElementToString = renderToString(translateTimeElement);
  const timeTranslateReplace = convertTimeElementToString.replace('<span>', '');
  const timeReplaceFinal = timeTranslateReplace.replace('</span>', '');

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.orders.home.createLabel" data-cy="OrdersCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.orders.home.createLabel">Create a new Order</Translate>
          </h2>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <form onSubmit={handleSubmit(saveEntity)}>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.orders.employee" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="employee"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    secondName: 'fullName',
                    type: 'string',
                    criteria: 'nameWithCode',
                    sort: 'person.firstName,asc',
                    predefinedQuery: 'employeeStatus.in=ACTIVE,SUSPENDED',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orders.scheduleTime" />
                </Label>
                <div className="date-picker-container position-relative">
                  <Controller
                    control={control}
                    name="scheduleTime"
                    render={({ field }) => (
                      <div>
                        <DatePicker
                          portalId="root-portal"
                          minDate={new Date()}
                          isClearable={false}
                          locale={locale === 'ro' ? ro : 'en'}
                          className={`form-control ${touchedFields.scheduleTime && !errors.scheduleTime ? 'is-valid' : ''} ${
                            errors.scheduleTime ? 'is-invalid' : ''
                          }`}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          timeInputLabel={timeReplaceFinal + ':'}
                          showMonthDropdown
                          showYearDropdown
                          showTimeInput
                          {...scheduleTimeProps}
                          ref={scheduleTimeRef}
                          selected={scheduleTimeInit ? new Date(scheduleTimeInit) : new Date()}
                          value={
                            showDate === true
                              ? moment(scheduleTimeInit).format(convertDateFormat(locale, 'instant'))
                              : convertDateFormat(locale, 'instant')
                          }
                          onChange={date => {
                            setSchDateShow(true);
                            field.onChange(date);
                            setSchDate(date);
                          }}
                        />
                        <span
                          onClick={() => {
                            setSchDateShow(false);
                            field.onChange('');
                            setSchDate(field.value);
                            if (watchScheduleTime) {
                              resetField('orderType');
                              setValue('orderType', null);
                              resetField('application');
                              setValue('application', null);
                              resetValidUntilAndTemporary();
                            }
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {showDate === true ? 'X' : ''}
                        </span>
                      </div>
                    )}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.orders.orderType" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  isPageable={false}
                  name="orderType"
                  url={selectOrderTypeSource(watchEmployee?.id)}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              {/* Cumulated field */}
              {watchOrderType?.appliedOn === AppliedOnTypes.POSITION ? (
                <FormGroup>
                  <Input type="checkbox" {...cumulatedProps} innerRef={cumulatedRef} onChange={handleCumulatedDelegation} />
                  <Label className="ml-1">
                    <Translate contentKey="iamdentityApp.orders.cumulated" />
                  </Label>
                </FormGroup>
              ) : null}
              {/* allApps field */}
              {showAllAppsField() ? (
                <FormGroup>
                  <Input type="checkbox" {...allAppsProps} innerRef={allAppsRef} />
                  <Label className="ml-1">
                    <Translate contentKey="iamdentityApp.orders.allApps" />
                  </Label>
                </FormGroup>
              ) : null}
              {/* Add user exception field */}
              {showAddUserExceptionField() ? (
                <FormGroup>
                  <Input type="checkbox" {...addUserExceptionProps} innerRef={addUserExceptionRef} />
                  <Label className="ml-1">
                    <Translate contentKey="iamdentityApp.orders.addUserException" />
                  </Label>
                </FormGroup>
              ) : null}
              {/* application field*/}
              {showApplicationField() && (!watchAllApps || watchAllApps === null) ? (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      *
                      <Translate contentKey="iamdentityApp.orders.application" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="application"
                      isPageable={false}
                      url={selectApplicationSource(
                        watchEmployee?.id,
                        watchScheduleTime,
                        watchOrderType?.forStandardPolicy,
                        watchScheduleTime?.temporary
                      )}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              ) : null}
              {showUserIdentity() ? (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      *
                      <Translate contentKey="iamdentityApp.userIdentity.userName" />
                    </Label>
                    <PaginatedReactSelect
                      validate={{ required: true }}
                      control={control}
                      name="userIdentity"
                      isPageable={false}
                      url={selectUserIdentitySource(
                        watchEmployee?.id,
                        watchApplication?.id,
                        watchScheduleTime,
                        watchOrderType?.forStandardPolicy,
                        watchScheduleTime?.temporary
                      )}
                      queryProps={{
                        name: 'userName',
                        secondName: 'period',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              ) : null}
              {/* USER specific  fields*/}
              {showUserFields() || watchAllApps === true ? showUserFieldsForm() : null}
              {showRoleFields() ? showRoleFieldsForm() : null}
              {showGroupFields() ? (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.orderedGroup.roleGroup" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="roleGroup"
                      isPageable={false}
                      url={selectGroupDefinionSource(
                        watchUserIdentity?.id,
                        watchScheduleTime,
                        watchOrderType?.forStandardPolicy,
                        watchScheduleTime?.temporary
                      )}
                      queryProps={{
                        name:
                          selectedOrderType?.appliedOn == AppliedOnTypes.GROUP &&
                          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW
                            ? 'nameWithPeriod'
                            : 'roleGroup.nameWithPeriod',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                  {watchRoleGrObj?.customAttributeValueMap &&
                    (selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
                      selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW) &&
                    watchRoleGrObj.customAttributeValueMap.map(cad =>
                      renderCustomAttributeField({
                        control: control,
                        name: cad.key.displayName,
                        locale: locale,
                        id: `user-identity-role-${cad.key.displayName}`,
                        label: cad.key.displayName,
                        attributeType: cad.key.customAttributeType,
                        'data-cy': cad.key.displayName,
                        register: register,
                        options:
                          cad.key.customAttributeType === CustomAttributeType.ENUMERATION
                            ? cad.key.selectableOptions
                            : cad.value[`${cad.key.customAttributeType.toLowerCase()}Value`],
                        value: cad.value[`${cad.key.customAttributeType.toLowerCase()}Value`],
                      })
                    )}
                </React.Fragment>
              ) : null}
              {showPermissionFields() ? (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      *<Translate contentKey="iamdentityApp.orderedSpecificPermission.permissionDefinition" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="permissionDefinition"
                      isPageable={false}
                      url={selectPermDefinionSource(
                        watchUserIdentity?.id,
                        watchScheduleTime,
                        watchOrderType?.forStandardPolicy,
                        watchScheduleTime?.temporary
                      )}
                      queryProps={{
                        name:
                          selectedOrderType?.appliedOn == AppliedOnTypes.PERMISSION &&
                          selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW
                            ? 'nameWithPeriod'
                            : 'permissionDefinition.nameWithPeriod',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                  {watchPermDefObj?.customAttributeValueMap &&
                    (selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
                      selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW) &&
                    watchPermDefObj.customAttributeValueMap.map(cad =>
                      renderCustomAttributeField({
                        control: control,
                        name: cad.key.displayName,
                        locale: locale,
                        id: `user-identity-role-${cad.key.displayName}`,
                        label: cad.key.displayName,
                        attributeType: cad.key.customAttributeType,
                        'data-cy': cad.key.displayName,
                        register: register,
                        options:
                          cad.key.customAttributeType === CustomAttributeType.ENUMERATION
                            ? cad.key.selectableOptions
                            : cad.value[`${cad.key.customAttributeType.toLowerCase()}Value`],
                        value: cad.value[`${cad.key.customAttributeType.toLowerCase()}Value`],
                      })
                    )}
                </React.Fragment>
              ) : null}
              {showEmployeeDelegation() ? (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.employeeDelegation.delegated" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="employeeDelegation"
                      isPageable={false}
                      url={selectEmployeeDelegationSource(watchEmployee?.id, watchScheduleTime)}
                      queryProps={{
                        name: 'delegatedName',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              ) : null}
              {showPositionFields() ? (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.employee.organizationUnit" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="organizationUnit"
                      isPageable={true}
                      url={organizationUnitsUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.employee.job" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="jobDefinition"
                      isPageable={true}
                      url={jobDefinitionsUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              ) : null}
              {/* Temporary field */}
              {showTemporaryField() ? (
                <FormGroup>
                  <Input type="checkbox" {...temporaryProps} innerRef={temporaryRef} />
                  <Label className="ml-1">
                    <Translate contentKey="iamdentityApp.orders.temporary" />
                  </Label>
                </FormGroup>
              ) : null}
              {watchTemporary ? (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orders.orderValidUntil" />
                  </Label>
                  <div className="date-picker-container position-relative">
                    <Controller
                      control={control}
                      name="orderValidUntil"
                      render={({ field }) => (
                        <div>
                          <DatePicker
                            portalId="root-portal"
                            minDate={watchScheduleTime ? watchScheduleTime : null}
                            isClearable={false}
                            locale={locale === 'ro' ? ro : 'en'}
                            className={`form-control ${touchedFields.orderValidUntil && !errors.orderValidUntil ? 'is-valid' : ''} ${
                              errors.orderValidUntil ? 'is-invalid' : ''
                            }`}
                            todayButton={<Translate contentKey="global.today">Today</Translate>}
                            timeInputLabel={timeReplaceFinal + ':'}
                            showMonthDropdown
                            showYearDropdown
                            showTimeInput
                            {...orderValidUntilProps}
                            ref={orderValidUntilRef}
                            selected={orderValidUntilInit ? new Date(orderValidUntilInit) : new Date()}
                            value={
                              showDateOrderValidUntil === true
                                ? moment(orderValidUntilInit).format(convertDateFormat(locale, 'instant'))
                                : convertDateFormat(locale, 'instant')
                            }
                            onChange={date => {
                              setSchDateShowOrderValidUntil(true);
                              field.onChange(date);
                              setSchDateOrderValidUntil(date);
                            }}
                          />
                          <span
                            onClick={() => {
                              setSchDateShowOrderValidUntil(false);
                              field.onChange('');
                              setSchDateOrderValidUntil(field.value);
                            }}
                            className="fw-semibold close-icon-custom"
                          >
                            {showDateOrderValidUntil === true ? 'X' : ''}
                          </span>
                        </div>
                      )}
                    />
                  </div>
                </FormGroup>
              ) : null}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.generate">Generate</Translate>
                </Button>
              </div>
            </form>
          )}
        </Col>
      </Row>
    </Card>
  );

  function showRoleFieldsForm(): React.ReactNode {
    return (
      <React.Fragment>
        <FormGroup>
          <Label>
            <Translate contentKey="iamdentityApp.orderedRole.roleDefinition" />
          </Label>
          <PaginatedReactSelect
            control={control}
            name="roleDefinition"
            isPageable={false}
            url={selectRoleDefinitionSource(
              watchUserIdentity?.id,
              watchApplication?.id,
              watchScheduleTime,
              watchOrderType?.forStandardPolicy,
              watchScheduleTime?.temporary
            )}
            queryProps={{
              name:
                selectedOrderType?.appliedOn == AppliedOnTypes.ROLE && selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW
                  ? 'nameWithPeriod'
                  : 'roleDefinition.nameWithPeriod',
              type: 'string',
            }}
          />
        </FormGroup>

        {watchRoleDefObj?.customAttributeValueMap &&
          (selectedOrderType?.modificationLevel == ModificationLevelType.MODIFY_EXISTING ||
            selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW) &&
          watchRoleDefObj?.customAttributeValueMap.map(cad =>
            renderCustomAttributeField({
              control: control,
              name: cad.key.displayName,
              locale: locale,
              id: `user-identity-role-${cad.key.displayName}`,
              label: cad.key.displayName,
              attributeType: cad.key.customAttributeType,
              'data-cy': cad.key.displayName,
              register: register,
              options:
                cad.key.customAttributeType === CustomAttributeType.ENUMERATION
                  ? cad.key.selectableOptions
                  : cad.value[`${cad.key.customAttributeType.toLowerCase()}Value`],
              value: cad.value[`${cad.key.customAttributeType.toLowerCase()}Value`],
            })
          )}
      </React.Fragment>
    );
  }

  function showUserFieldsForm(): React.ReactNode {
    return (
      <React.Fragment>
        {selectedOrderType?.appliedOn == AppliedOnTypes.USER && selectedOrderType?.modificationLevel == ModificationLevelType.ADD_NEW && (
          <FormGroup>
            <Label>
              *
              <Translate contentKey="iamdentityApp.orderedUserIdentity.userName" />
            </Label>
            <Input
              required={true}
              valid={touchedFields.userName && !errors.userName}
              invalid={!!errors.userName}
              type="text"
              {...userNameProps}
              innerRef={userNameRef}
            />
          </FormGroup>
        )}
        {selectedOrderType?.appliedOn == AppliedOnTypes.USER && selectedOrderType?.modificationLevel == ModificationLevelType.PASSWORD ? (
          <FormGroup>
            <Label>
              <Translate contentKey="iamdentityApp.orderedUserIdentity.initialPassword" />
            </Label>
            <Input
              valid={touchedFields.initialPassword && !errors.initialPassword}
              invalid={!!errors.initialPassword}
              type="password"
              {...initialPasswordProps}
              innerRef={initialPasswordRef}
            />
          </FormGroup>
        ) : (
          <div>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.alternativeUserName" />
              </Label>
              <Input
                valid={touchedFields.alternativeUserName && !errors.alternativeUserName}
                invalid={!!errors.alternativeUserName}
                type="text"
                {...alternativeUserNameProps}
                innerRef={alternativeUserNameRef}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.associatedEmailAddress" />
              </Label>
              <Input
                valid={touchedFields.associatedEmailAddress && !errors.associatedEmailAddress}
                invalid={!!errors.associatedEmailAddress}
                type="text"
                {...associatedEmailAddressProps}
                innerRef={associatedEmailAddressRef}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.displayName" />
              </Label>
              <Input
                valid={touchedFields.displayName && !errors.displayName}
                invalid={!!errors.displayName}
                type="text"
                {...displayNameProps}
                innerRef={displayNameRef}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.initialPassword" />
              </Label>
              <Input
                valid={touchedFields.initialPassword && !errors.initialPassword}
                invalid={!!errors.initialPassword}
                type="password"
                {...initialPasswordProps}
                innerRef={initialPasswordRef}
              />
            </FormGroup>
            <FormGroup>
              <Input type="checkbox" {...mustChangePasswordOnFirstLoginProps} innerRef={mustChangePasswordOnFirstLoginRef} />
              <Label className="ml-1">
                <Translate contentKey="iamdentityApp.orderedUserIdentity.mustChangePasswordOnFirstLogin" />
              </Label>
            </FormGroup>

            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.dateNextChangePassword" />
              </Label>
              <Input
                valid={touchedFields.dateNextChangePassword && !errors.dateNextChangePassword}
                invalid={!!errors.dateNextChangePassword}
                type="select"
                {...dateNextChangePasswordProps}
                innerRef={dateNextChangePasswordRef}
              >
                <option value="" key="0" />
                {Object.values(DateNextChangePasswordOptions).map(val => (
                  <option value={val} key="0">
                    <Translate contentKey={`iamdentityApp.orderedUserIdentity.dateNextChangePasswordOptions.${val}`} />
                  </option>
                ))}
              </Input>
            </FormGroup>
            {orderedUserIdentityCustomAttributes?.map(cad =>
              renderAndRegisterCustomAttributeField({
                name: cad.displayName,
                label: cad.displayName,
                attributeType: cad.customAttributeType,
                register,
                options: cad.selectableOptions,
              })
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
};

export default OrdersUpdate;
