import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IProject } from 'app/shared/model/project.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { getEntities as getEmployees } from 'app/entities/employee/employee.reducer';
import { IProjectAssignment } from 'app/shared/model/project-assignment.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { useForm } from 'react-hook-form';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { createEntity, getEntity, reset, updateEntity } from './project-assignment.reducer';
import { apiUrl as employeesUrl } from 'app/entities/employee/employee.reducer';

import { apiUrl as projectsUrl } from 'app/entities/project/project.reducer';

import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { useRef } from 'react';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { getEntities as getProjects } from 'app/entities/project/project.reducer';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';

export const ProjectAssignmentUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const projects = useAppSelector(state => state.project.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const projectAssignmentEntity = useAppSelector(state => state.projectAssignment.entity);
  const loading = useAppSelector(state => state.projectAssignment.loading);
  const updating = useAppSelector(state => state.projectAssignment.updating);
  const updateSuccess = useAppSelector(state => state.projectAssignment.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate('/project-assignment' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROJECT_ASSIGNMENT));
    dispatch(getProjects({}));
    dispatch(getEmployees({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [projectAssignmentEntity]);

  const saveEntity = values => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(
        projectAssignmentEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
    };
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: displayDefaultDateTime(),
          endDate: displayDefaultDateTime(),
        }
      : {
          ...projectAssignmentEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, projectAssignmentEntity),
          startDate: convertDateTimeFromServer(projectAssignmentEntity.startDate),
          endDate: convertDateTimeFromServer(projectAssignmentEntity.endDate),
          //       project: projectAssignmentEntity?.project?.id,
          //       employee: projectAssignmentEntity?.employee?.id,
          project: projectAssignmentEntity?.project,
          employee: projectAssignmentEntity?.employee,
        };
    startDate.current = result?.startDate;
    return result;
  };
  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });
  return (
    <Card className="jh-card pad-1">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.projectAssignment.home.createOrEditLabel" data-cy="ProjectAssignmentCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.projectAssignment.home.createOrEditLabel">Create or edit a ProjectAssignment</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="project-assignment-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.projectAssignment.startDate')}
                id="project-assignment-startDate"
                name="startDate"
                data-cy="startDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                onChange={event => (startDate.current = event.target.value)}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.projectAssignment.endDate')}
                id="project-assignment-endDate"
                name="endDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                min={startDate.current}
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.projectAssignment.project" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="project"
                  url={projectsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                    criteria: 'name',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.projectAssignment.employee" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="employee"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    secondName: 'fullName',
                    type: 'string',
                    criteria: 'nameWithCode',
                    sort: 'person.firstName,asc',
                  }}
                />
              </FormGroup>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register: register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `project-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/project-assignment" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectAssignmentUpdate;
