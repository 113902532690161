import { INPUT_ENUM_SEPARATOR } from 'app/config/constants';
import pick from 'lodash/pick';
import { IPaginationBaseState, translate } from 'react-jhipster';
import { CustomAttributeDto, CustomAttributeDtoKey, CustomAttributeDtoValue } from '../model/custom-attribute-definition.model';
import { CustomAttributeType } from '../model/enumerations/custom-attribute-type.model';
import { convertDateFormat } from 'app/shared/util/date-utils';
import moment from 'moment';
/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with an empty id and thus resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = entity => {
  const keysToKeep = Object.keys(entity).filter(
    k => entity[k] !== '' && (!(entity[k] instanceof Object) || (entity[k]['id'] !== '' && entity[k]['id'] !== -1))
  );
  return pick(entity, keysToKeep);
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) => idList.filter((id: any) => id !== '').map((id: any) => ({ id }));

export const overridePaginationStateWithQueryParams = (paginationBaseState: IPaginationBaseState, locationSearch: string) => {
  const params = new URLSearchParams(locationSearch);
  const page = params.get('page');
  const sort = params.get('sort');
  if (page && sort) {
    const sortSplit = sort.split(',');
    paginationBaseState.activePage = +page;
    paginationBaseState.sort = sortSplit[0];
    paginationBaseState.order = sortSplit[1];
  }
  return paginationBaseState;
};

export const getCustomAttributeValue = (customAttribute: CustomAttributeDto) => {
  switch (customAttribute?.key?.customAttributeType) {
    case CustomAttributeType.BOOLEAN:
      return Boolean(customAttribute.value.booleanValue);
    case CustomAttributeType.DATE:
      return customAttribute.value.dateValue;
    case CustomAttributeType.ENUMERATION:
      return customAttribute.value.enumerationValue;
    case CustomAttributeType.MULTISELECT:
      return customAttribute.value.multiselectValue;
    case CustomAttributeType.NUMBER:
      return customAttribute.value.numberValue;
    case CustomAttributeType.TEXT:
      return customAttribute.value.textValue;
    default:
      return '';
  }
};

export const findCustomAttributeValuePropertyByType = (type: CustomAttributeType, value: any): { key: string; value: any } => {
  switch (type) {
    case CustomAttributeType.BOOLEAN:
      return { key: 'booleanValue', value: Boolean(value) };
    case CustomAttributeType.DATE:
      return { key: 'dateValue', value };
    case CustomAttributeType.ENUMERATION:
      return { key: 'enumerationValue', value };
    case CustomAttributeType.MULTISELECT:
      return { key: 'multiselectValue', value };
    case CustomAttributeType.NUMBER:
      return { key: 'numberValue', value: Number(value) };
    case CustomAttributeType.TEXT:
      return { key: 'textValue', value };
    default:
      return { key: 'textValue', value };
  }
};

export const findCustomAttributeValueByKey = (customAttributeDefinition: CustomAttributeDtoKey, entity: any, locale?: string) => {
  const customAttributeValueMap: CustomAttributeDto[] = entity.customAttributeValueMap ?? [];
  const foundCustomAttribute = customAttributeValueMap.find(ca => ca.key.id == customAttributeDefinition.id);
  let value = getCustomAttributeValue(foundCustomAttribute);

  if (foundCustomAttribute?.key?.customAttributeType == CustomAttributeType.BOOLEAN) {
    value = value ? translate('global.yes') : translate('global.no');
  }

  if (foundCustomAttribute?.key?.customAttributeType == CustomAttributeType.MULTISELECT) {
    if (typeof value === 'string' && value) {
      const valuesArray = value.split('|');
      value = valuesArray.length > 1 ? valuesArray.join(', ') : valuesArray[0];
    }
  }

  if (foundCustomAttribute?.key?.customAttributeType == CustomAttributeType.DATE) {
    if (typeof value === 'string' && value) {
      const dateFormat = convertDateFormat(locale, 'date');
      value = moment(value).format(dateFormat);
    }
  }

  return value;
};

export const convertReactSelectValuesToEntityIds = (values: { [key: string]: any }) => {
  const newValues = {};
  for (const [key, value] of Object.entries(values)) {
    if (
      key != 'customAttributeValueMap' &&
      value &&
      typeof value === 'object' &&
      value.hasOwnProperty('value') &&
      value.hasOwnProperty('label')
    ) {
      newValues[key] = { id: value.value };
    } else if (key != 'customAttributeValueMap' && value && typeof value === 'object' && value.hasOwnProperty('id')) {
      newValues[key] = { id: value.id };
    } else {
      newValues[key] = value;
    }
  }

  return newValues;
};

function replaceKeyCustomAttribues(values: any) {
  let obj: any = {};
  for (let [key, value] of Object.entries(values)) {
    if (key.includes('.')) {
      let replaceKey = key.replaceAll('.', '_#_');
      obj = { ...obj, [`${replaceKey}`]: value };
      continue;
    }

    if (key.includes('_#_')) {
      let replaceKey = key.replaceAll('_#_', '.');
      obj = { ...obj, [`${replaceKey}`]: value };
      continue;
    }

    obj = { ...obj, [`${key}`]: value };
  }

  return obj;
}

export const extractCustomAttributesAsEntityProperties = (customAttributeDefinitions: CustomAttributeDtoKey[], entity: any) => {
  let customAttributesMap = {};

  for (const def of customAttributeDefinitions) {
    const key = def.displayName;
    const customAttributeValueMap: CustomAttributeDto[] = entity.customAttributeValueMap ?? [];
    const foundCustomAttribute = customAttributeValueMap.find(ca => ca.key.id == def.id);
    let value = getCustomAttributeValue(foundCustomAttribute);
    if (foundCustomAttribute) {
      if (key && value) {
        customAttributesMap[key] = value;
      }
    } else {
      if (def.customAttributeType == CustomAttributeType.BOOLEAN) {
        value = false;
      } else if (def.customAttributeType == CustomAttributeType.NUMBER) {
        value = null;
      } else {
        value = '';
      }
      customAttributesMap[key] = value;
    }
  }

  let newCustomAttributesMap = replaceKeyCustomAttribues(customAttributesMap);

  return { ...entity, ...newCustomAttributesMap };
};

export const addCustomPropertiesToCustomAttributesMap = (
  entity: any,
  values: any,
  definitions: CustomAttributeDtoKey[],
  onlyCustom: boolean = false
) => {
  let newCustomAttributesMap = replaceKeyCustomAttribues(values);

  let newValues = {};
  if (!onlyCustom) {
    newValues = { ...newCustomAttributesMap };
  }
  let valuesMap = [];

  for (const def of definitions) {
    if (newCustomAttributesMap.hasOwnProperty(def.displayName)) {
      delete newValues[def.displayName];
      const key = { ...def };
      const foundValue = newCustomAttributesMap[def.displayName];

      const foundCustomAttribute = newCustomAttributesMap?.customAttributeValueMap?.find(ca => ca.key.id == def.id);
      let value: CustomAttributeDtoValue = {
        booleanValue: null,
        dateValue: null,
        enumerationValue: '',
        multiselectValue: '',
        id: null,
        numberValue: null,
        textValue: '',
      };

      if (foundCustomAttribute) {
        value = { ...foundCustomAttribute.value };
      }

      const attributeValueProp = findCustomAttributeValuePropertyByType(key.customAttributeType, foundValue);

      value = {
        ...value,
        [attributeValueProp.key]: attributeValueProp.value,
      };

      valuesMap.push({ key, value });
    }
  }

  newValues['customAttributeValueMap'] = valuesMap;

  return {
    ...entity,
    ...newValues,
  };
};

export const getFiltersForCustomAttributes = (attributeDefinitions: CustomAttributeDtoKey[]): any[] => {
  const filters = [];
  for (const def of attributeDefinitions) {
    let name = def.displayName;
    let type = '';
    let values = null;

    switch (def.customAttributeType) {
      case CustomAttributeType.ENUMERATION:
        name = `customAttributeEnum[${def.id}]`;
        type = 'select';
        values = [
          { value: 'placeholder', label: '' },
          ...def.selectableOptions.split(INPUT_ENUM_SEPARATOR).map(v => {
            return { value: v, label: v };
          }),
        ];
        break;
      case CustomAttributeType.MULTISELECT:
        name = `customAttributeMultiselect[${def.id}]`;
        type = 'string';
        break;
      case CustomAttributeType.BOOLEAN:
        name = `customAttributeBoolean[${def.id}]`;
        type = 'boolean';
        break;
      case CustomAttributeType.DATE:
        name = `customAttributeDate[${def.id}]`;
        type = 'date';
        break;
      case CustomAttributeType.NUMBER:
        name = `customAttributeNumber[${def.id}]`;
        type = 'number';
        break;
      case CustomAttributeType.TEXT:
      default:
        name = `customAttributeString[${def.id}]`;
        type = 'string';
        break;
    }
    filters.push({ name, type, values });
  }
  return filters;
};
