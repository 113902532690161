import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './project-assignment.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const ProjectAssignmentDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROJECT_ASSIGNMENT));
  }, []);

  const projectAssignmentEntity = useAppSelector(state => state.projectAssignment.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card pad-1">
          <Col md="8">
            <h2 data-cy="projectAssignmentDetailsHeading">
              <Translate contentKey="iamdentityApp.projectAssignment.detail.title">ProjectAssignment</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{projectAssignmentEntity.id}</dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.projectAssignment.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {projectAssignmentEntity.startDate ? (
                  <TextFormat value={projectAssignmentEntity.startDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.projectAssignment.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {projectAssignmentEntity.endDate ? (
                  <TextFormat value={projectAssignmentEntity.endDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.projectAssignment.project">Project</Translate>
              </dt>
              <dd>{projectAssignmentEntity.project ? projectAssignmentEntity.project.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.projectAssignment.employee">Employee</Translate>
              </dt>
              <dd>
                {projectAssignmentEntity.employee
                  ? projectAssignmentEntity.employee.fullName
                    ? projectAssignmentEntity.employee?.code + ' - ' + projectAssignmentEntity.employee.fullName
                    : projectAssignmentEntity.employee?.code
                  : ''}
              </dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, projectAssignmentEntity, locale)}</dd>
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{projectAssignmentEntity.createdBy ? projectAssignmentEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {projectAssignmentEntity.createdDate ? (
                  <TextFormat value={projectAssignmentEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{projectAssignmentEntity.lastModifiedBy ? projectAssignmentEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {projectAssignmentEntity.lastModifiedDate ? (
                  <TextFormat value={projectAssignmentEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <Button tag={Link} to="/project-assignment" replace color="info" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/project-assignment/${projectAssignmentEntity.id}/edit`} color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
            &nbsp;
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.PROJECT_ASSIGNMENT} />
    </React.Fragment>
  );
};

export default ProjectAssignmentDetail;
